import { Document } from "@/repositories/supplier/model/Document";
import { DocumentType } from "@/repositories/supplier/model/DocumentType";
import { httpClient, AuthHeaders } from "@cumulus/http";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_DOCUMENT != "undefined" && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT + "/documents"
        : `${import.meta.env.VITE_APP_API_URL as string}/documents`;
  }

  public async getDocumentUrl(authHeaders: AuthHeaders, fileName: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("", { params: { documentName: fileName, documentType: DocumentType.Supplier } })
      .then(({ data }) => data);
  }

  public async upload(authHeaders: AuthHeaders, file: FormData): Promise<Document> {
    return await httpClient(this.uri, authHeaders)
      .postForm("", file, { params: { documentType: DocumentType.Supplier } })
      .then(({ data }) => data);
  }
}

const documentApi = new DocumentApi();
export { documentApi };
