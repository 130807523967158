import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { Product } from "./model/Product";
import { NewProduct } from "./model/NewProduct";
import { productApi } from "./ProductApi";
import { UpdateProduct } from "./model/UpdateProduct";
import { ProductVariantsRequest } from "./model/ProductVariantsRequest";
import { ProductImage } from "./model/ProductImage";

export const useProductStore = defineStore("product", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const products = ref<Product[]>([]);
  const loading = ref(false);
  const saving = ref(false);

  const productById = (id: string): Product => {
    return products.value.find((product) => product.id === id) ?? new Product();
  };

  const getProductById = async (id: string): Promise<Product> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductsByIds = async (ids: string[]): Promise<Product[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProductsByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const fetchProducts = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      products.value = await productApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const createProduct = async (product: NewProduct): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.create(authHeaders, product);
      // products.value.push(createdProduct);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const createProductVariants = async (request: ProductVariantsRequest): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.createVariants(authHeaders, request);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateProduct = async (product: Product, imagesToDelete: ProductImage[]): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      const updateProduct = new UpdateProduct(product, imagesToDelete);
      await productApi.update(authHeaders, updateProduct);
      const index = products.value.findIndex((cg) => cg.id === product.id);
      products.value[index] = product;
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const deleteProduct = async (productId: string): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.delete(authHeaders, productId);
      products.value = products.value.filter((cg) => cg.id !== productId);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  return {
    products,
    loading,
    saving,
    getProductById,
    getProductsByIds,
    productById,
    fetchProducts,
    createProduct,
    createProductVariants,
    updateProduct,
    deleteProduct,
  };
});
