import { Product } from "@/models/product/Product";
import { httpClient, AuthHeaders } from "@cumulus/http";

class ProductApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Product> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }
}
const productApi = new ProductApi();
export { productApi };
