import { NIL as emptyUuid } from "uuid";
import { Weight } from "./Weight";
import { Dimension } from "./Dimension";
import { ProductType } from "./ProductType";
import { ProductImage } from "./ProductImage";
import { SupplierPrice } from "./SupplierPrice";
import { Price } from "./Price";
import { ProductAttribute } from "./ProductAttribute";
import { ProductStatus } from "./ProductStatus";
import { ProductTax } from "./ProductTax";
import { Media } from "./Media";
import { ProductDocument } from "./ProductDocument";
import { ProductStructureType } from "./ProductStructureType";

export class Product {
  id = emptyUuid;
  companyId = emptyUuid;
  name = "";
  productNumber = "";
  gtin = "";
  description = "";
  salesUnitId = emptyUuid;
  productHierarchyId = emptyUuid;
  weight = new Weight();
  dimension = new Dimension();
  productType: ProductType = ProductType.Merchandise;
  productInfo = "";
  productImages: ProductImage[] = [];
  media: Media[] = [];
  supplierPrices: SupplierPrice[] = [];
  prices: Price[] = [];
  alternativeProductIds: string[] = [];
  accessoryProductIds: string[] = [];
  attributes: ProductAttribute[] = [];
  clientIds: string[] = [];
  warehouseIds: string[] = [];
  manufacturerId = emptyUuid;
  accountGroupId = emptyUuid;
  taxes: ProductTax[] = [];
  activeOnWebshop = true;
  productStatus: ProductStatus = ProductStatus.Active;
  dateAdded = new Date().toJSON();
  documents: ProductDocument[] = [];
  parentProductId: string | null = null;
  variantChildProductIds: string[] = [];
  variantAttributes: ProductAttribute[] = [];
  structureType: ProductStructureType = ProductStructureType.Standard;

  constructor(product: Product | null = null) {
    if (product === null) {
      return;
    }

    this.id = product.id;
    this.companyId = product.companyId;
    this.name = product.name;
    this.productNumber = product.productNumber;
    this.gtin = product.gtin;
    this.description = product.description;
    this.salesUnitId = product.salesUnitId;
    this.productHierarchyId = product.productHierarchyId;
    this.weight = product.weight;
    this.dimension = product.dimension;
    this.productType = product.productType;
    this.productInfo = product.productInfo;
    this.productImages = product.productImages;
    this.media = product.media;
    this.supplierPrices = product.supplierPrices;
    this.prices = product.prices;
    this.alternativeProductIds = product.alternativeProductIds;
    this.accessoryProductIds = product.accessoryProductIds;
    this.attributes = product.attributes;
    this.clientIds = product.clientIds;
    this.warehouseIds = product.warehouseIds;
    this.manufacturerId = product.manufacturerId;
    this.accountGroupId = product.accountGroupId;
    this.taxes = product.taxes;
    this.activeOnWebshop = product.activeOnWebshop;
    this.productStatus = product.productStatus;
    this.dateAdded = product.dateAdded;
    this.documents = product.documents;
    this.parentProductId = product.parentProductId;
    this.variantChildProductIds = product.variantChildProductIds;
    this.variantAttributes = product.variantAttributes;
    this.structureType = product.structureType;
  }
}
