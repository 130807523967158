<template>
  <div class="border rounded-lg p-4 h-full flex flex-col bg-white shadow-sm transition-all duration-200 mr-2">
    <div class="mb-6">
      <div class="relative mx-auto">
        <img
          :src="currentFileIcon(document.documentFileType as FileType)"
          :alt="document.heading"
          class="w-24 h-24 object-cover rounded justify-center"
          @click="documentOnClick"
        />
        <span
          class="absolute top-1 right-1 bg-primary text-white rounded-full w-6 h-6 flex items-center justify-center text-sm"
        >
          {{ index + 1 }}
        </span>
      </div>
    </div>
    <div v-if="isEditing" class="flex-grow">
      <InputText
        v-model="editingName"
        :placeholder="t('product.documents.name')"
        class="btn-change-title w-full mb-3 text-sm"
      />
      <PrimeTextarea
        v-model="editingDescription"
        :placeholder="t('product.documents.description')"
        class="w-full mb-3 text-sm"
        rows="3"
      />
      <div class="flex justify-end space-x-2">
        <PrimeButton :label="t('common.cancel')" class="p-button-text p-button-sm" @click="cancelEdit" />
        <PrimeButton :label="t('common.save')" class="p-button-sm p-close-button" @click="saveEdit" />
      </div>
    </div>
    <div v-else class="flex-grow">
      <h3 class="text-sm font-semibold mb-3 truncate">
        {{ document.heading || t("product.documents.unnamed-document") }}
      </h3>
      <p class="text-sm text-gray-600 mb-3 line-clamp-5">
        {{ document.description || t("product.documents.no-description") }}
      </p>
      <div class="flex justify-between items-center">
        <PrimeButton icon="pi pi-pencil" class="btn-edit-document p-button-text p-button-lg" @click="startEdit" />
        <PrimeButton icon="pi pi-trash" class="p-button-text p-button-danger p-button-lg" @click="onConfirmDelete" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import pdfNewIcon from "@/assets/PDF_new_file_icon.svg";
import docIcon from "@/assets/DOC_file_icon.svg";
import txtIcon from "@/assets/TXT_file_icon.svg";
import { ProductDocument } from "@/repositories/product/model/ProductDocument";
import { useDocumentService } from "@/product/api/document/DocumentService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { FileType } from "@/repositories/product/model/FileType";

const { t } = useI18n();

const toast = useCumulusToast(useToast());
const { getDocumentUrl } = useDocumentService();
const confirm = useConfirm();
const props = defineProps<{
  document: ProductDocument;
  index: number;
}>();

const emit = defineEmits<{
  (e: "deleteDocument"): void;
  (e: "updateDocument", document: ProductDocument): void;
}>();

const startEdit = () => {
  isEditing.value = true;
  editingName.value = props.document.heading;
  editingDescription.value = props.document.description;
};

const saveEdit = () => {
  const updatedDocument = {
    ...props.document,
    heading: editingName.value,
    description: editingDescription.value,
  };
  emit("updateDocument", updatedDocument);
  cancelEdit();
};

const isEditing = ref(false);
const editingName = ref("");
const editingDescription = ref("");

const cancelEdit = () => {
  isEditing.value = false;
  editingName.value = "";
  editingDescription.value = "";
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("deleteDocument");
    },
  });
};

const currentFileIcon = (fileType: FileType) => {
  switch (fileType) {
    case FileType.PDF:
      return pdfNewIcon;
    case FileType.DOC:
      return docIcon;
    case FileType.DOCX:
      return docIcon;
    case FileType.TXT:
      return txtIcon;
    case FileType.RTF:
      return docIcon;
    default:
      return pdfNewIcon;
  }
};

const documentOnClick = async () => {
  const documentName = props.document.documentUrl;
  if (documentName) {
    const documentUrl = await getDocumentUrl(documentName);

    if (documentUrl && documentUrl.length > 0) {
      window.open(documentUrl);
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("product.download-link-missing"),
      });
    }
  }
};
</script>

<style scoped>
.c-transform {
  transition: transform 0.2s ease-in-out;
}
</style>
