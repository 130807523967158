{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "min-length": "Minimum length is {min}",
    "exists": "{property} already exists"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "created": "Created",
    "add": "Add",
    "cancel": "Cancel",
    "update": "Update",
    "create": "Create",
    "delete": "Delete",
    "close": "Close",
    "search": "Search",
    "date": "Date",
    "reload": "Reload",
    "show-message": "Show message",
    "error": "An error occured",
    "error-load": "Failed to load {entity}, readon: {errorReason}",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text":"You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard":"Discard",
    "save":"Save",
    "current-page-template": "Showing {first} to {last} of {totalRecords}",
    "save-tooltip": "No changes",
    "message": {
      "validate-error": "Validation failed, correct all red fields and try again"
    },
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "error-detail": {
      "error-occured": "An error occured",
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Not found",
      "bad-request": "Server cannot process the request"
    },

    "role": {
      "reader": "Reader",
      "contributor": "Contributor",
      "admin": "Administrator"
    },
    "address": {
      "add": "Add address",
      "edit": "Edit address",
      "address-lines": "Address lines",
      "address-lines-error": "At least one address line is required",
      "postal-code-city": "Postal code/city",
      "city": "City",
      "country": "Country"
    },
    "filters":{
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filters":"Clear filters",
      "filter":"Filter",
      "filter-employee-status":"Employee status",
      "filter-status-active": "Active",
      "filter-status-inactive":"Inactive",
      "filter-clients": "Clients",
      "filter-roles":"Roles",
      "filter-deactivation-date":"Deactivation date",
      "filter-date-from":"From",
      "filter-date-to": "To"
    }  
  },

  "employee": {
    "employee": "Employee",
    "employees": "Employees",
    "search-employee":"Search Employee",
    "add-employee": "Add Employee",
    "edit-employee":"Edit Employee",
    "export": "Export",
    "languages": {
      "no": "Norwegian",
      "en": "English"
    },
    "id": "EmployeeId",
    "email": "Username/Email",
    "roles": "Roles",
    "clients": "Clients",
    "default-client": "Default client",
    "default-warehouse": "Default warehouse",
    "first-name": "First name",
    "last-name": "Last name",
    "employee-state": "Employee state",
    "status": {
      "active": "Active",
      "inactive": "Inactive"
    },
    "language": "Language",
    "phone-number": "Phone number",
    "start-date": "Start date",
    "deactivation-date": "Deactivation date",
    "initials": "Initials",
    "active": "Active",
    "cancel": "Cancel",
    "update": "Update",
    "add": "Add",
    "delete": {
      "header": "Delete employee",
      "dialog": "Are you sure you want to delete this employee?"
    },
    "created-message": "New employee created: ",
    "toast": {
      "edit": {
        "summary": "Employee updated",
        "detail": "For employee {email}"
      },
      "delete": {
        "success": {
          "summary": "Employee deleted"
        },
        "error": {
          "summary": "Could not delete employee"
        }
      },
      "reload": {
        "summary": "Reloading employee, please wait..."
      },
      "load": {
        "error": {
          "summary": "Failed to load employees"
        }
      },
      "open-employee-failed":"Failed to open employee"
    },

    "search": {
      "filter": "Filter by keyword",
      "search-label": "Employee search:",
      "more-filters": "More filters",
      "total-hits": "{totalHits} employees found",
      "add-btn": "New employee",
      "advanced-btn": "Advanced",
      "select-all": "Select all",
      "delete-selected": "Delete selected",
      "edit-columns": "Customize columns",
      "header-first-name": "First name",
      "header-last-name": "Last name",
      "header-initials": "Initials",
      "header-phone-number": "Phone number",
      "header-deactivation-date": "Deactivation date",
      "header-registration-date":"Registered",
      "header-email":"Email",
      "header-default-client": "Default client",
      "header-employee-status":"Status",
      "header-employee-role":"Roles",
      "search-error": "Search failed",
      "empty-list": "No employees found",
      "loading-list": "Loading employees, please wait..."
    },
    "common": {
      "add": "Add",
      "cancel": "Cancel",
      "search": "Search",
      "clear": "Clear",
      "yes": "Yes",
      "no": "No",
      "save": "Save"
    },
    "panels":{
      "prefrence": "Prefrences",
      "theme": "Theme"
    },
    "current-page-template": "Showing {first} to {last} of {totalRecords} employees",
    "input-style": "Input style",
    "input-style-type": {
      "default": "Default",
      "filled": "Filled background",
      "minimal": "Minimalistic"
    },
    "header-style": "Header style",
    "header-style-type": {
      "default": "Default",
      "twolines": "Two lines"
    },
    "datatable-style": "DataTable layout",
    "datatable-style-type": {
      "default": "Default",
      "striped": "Striped",
      "gridlines": "GridLines"
    },
    "menu-style": "Menu layout",
    "menu-style-type": {
      "default": "Default",
      "compact": "Compact"      
    }
  },
  "company": {
    "header-info": "Company information",
    "info": {
      "toast": {
        "success": {
          "summary": "Company was updated",
          "detail": "Changes are soon ready"
        },
        "error": {
          "summary": "Update failed",
          "detail": "The changes er not saved"
        }
      }
    },
    "name": "Name",
    "email": "E-mail",
    "organization-number": "Organization number",
    "registration-date": "Registration date",
    "telephone-number": "Telephone number"
  },
  "client": {
    "import-logo": "Import logo",
    "logo": "Logo",
    "client": "Client",
    "clients": "Clients",
    "search-client":"Search Client",
    "add-client": "Add Client",
    "edit-client":"Edit Client",
    "export": "Export",
    "confirm-delete": "Are you sure you want to delete this client?",
    "shipping-address": "Shipping address",
    "name": "Client name",
    "business-number": "Business number",
    "phone-number": "Phone number",
    "client-status": "Client status",
    "client-address":"Client address",
    "email": "Email",
    "currency": "Currency",
    "country": "Country",
    "delete": {
      "dialog": "Are you sure you want to delete this client?"
    },
    "banking": {
      "header": "Banking information",
      "name": "Name",
      "account-number": "Account number"
    },
    "message": {
      "reloading-client": "Reloading client, please wait...",
      "message-info": "Under construction",
      "success-add": "Client added!",
      "success-update": "Client updated!",
      "success-delete": "Client deleted!",
      "error-load": "Failed to load clients, please try again later",
      "error-add": "Could not create client, please try again later",
      "error-update": "Could not update client, please try again later",
      "error-delete": "Could not delete client, please try again later"
    },
    "toast": {
      "success": {
        "summary": "Client updated",
        "detail": "For client {clientname}"
      },
      "reload": {
        "summary": "Reloading client, please wait..."
      },
      "load": {
        "error": {
          "summary": "Failed to load clients"
        }
      },
      "error": {
        "summary": "An error occured",
        "detail": "Could not update client, please try again later",
        "file-upload-failed": "Filupload failed.",
        "file-upload-failed-detail": "Failed to upload file, please try again later"
      },
      "delete": {
        "success": {
          "summary": "Client deleted",
          "warning": "For client {clientName}"
        },
        "warn": {
          "summary": "An error occured",
          "detail": "Could not delete client, please try again later"
        }
      },
      "open-client-failed":"Failed to open client",
      "no-client-selected":"Client not selected"
    },
    "status": {
      "active": "Active",
      "inactive": "Inactive"
    },
    "search": {
      "filter": "Filter by keyword",
      "search-label": "Client search:",
      "more-filters": "More filters",
      "total-hits": "{totalHits} clients found",
      "advanced-btn": "Advanced",
      "select-all": "Select all",
      "delete-selected": "Delete selected",
      "edit-columns": "Customize columns",
      "header-name": "Client name",
      "header-client-status": "Client status",
      "search-error": "Search failed",
      "empty-list": "No clients found.",
      "loading-list": "Loading clients, please wait...",
      "current-page": "Viser {first} til {last} av {totalRecords}",
      "header-registered":"Registered",
      "header-business-number":"Business number",
      "header-phone":"Phone number",
      "header-email":"Email",
      "header-country": "Country"
    },
    "panels":{
      "general-panel": "General"
    },
    "invalid-file-size": "Invalid file size. Should be smaller than",
    "invalid-file-type": "Invalid file type. Only {fileTypes} is allowed"
  },

  "import": {
    "file":{
      "suppliers": "1. Import supplier CSV file",
      "customers": "2. Import Business Customer file",
      "products": "3. Import Product file"
    },
    "company-name": "Import to {companyName}",
    "importing": "Importing...",
    "success": "Successfully imported {importType}",
    "error": "Failed to import {importType}",
    "important-requirement": "Important requirement - Manually add shipping method \"Pick up\" after a new company is established. Link:",
    "shipping-method": "Shipping method"

  },

  "settings": {
    "settings-header": "Settings",
    "freight": {
      "header": "Freight",
      "freight-method": "Freight method"
    },    
    "warehouse": {
      "header": "Warehouse",
      "warehouse-items": "Warehouse configuration"
    },
    "attribute": {
      "header": "Attribute",
      "attributes": "Attributes"
    },
    "import": {
      "header": "Import",
      "imports": "Imports"
    }
  }
}
