<template>
  <div class="mt-4">
    <FloatLabelInput
      id="contact-email"
      v-model:value="orderReferenceComputed"
      :label="t('order.contact.email-label')"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('order.contact.email-label').toLowerCase() })"
      :dataTestId="'contact-email'"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";

const { t } = useI18n();

const props = defineProps<{
  email: string;
  orderStatus?: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const orderReferenceComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});
const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>
