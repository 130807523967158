<template>
  <div class="mt-4">
    <FloatLabelDropdown
      id="customer-type"
      dataTestId="customer-type"
      v-model:value="isBusinessCustomerComputed"
      :options="options"
      class="pt-2"
      :label="t(`customer.customer-type`)"
      :isFloatingLabel="isFloatingLabel"
    />
  </div>
  <!-- Hack for miss PrimeVue css variables from CumulusComponents -->
  <Select style="display: none"></Select>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CustomerType } from "@/models/customer/CustomerType";

const { t } = useI18n();

const props = defineProps<{
  customerType: CustomerType;
}>();

const emit = defineEmits<{
  (e: "update:customerType", value: string): void;
}>();

const isBusinessCustomerComputed = computed<string>({
  get: () => {
    return props.customerType ?? CustomerType.Business;
  },
  set: (value) => {
    emit("update:customerType", value);
  },
});

const options = ref<Record<string, string>[]>([]);
const fetchCustomerTypes = () => {
  for (const type in CustomerType) {
    options.value.push({
      name: t(`customer.type.${type.toString().toLowerCase()}`),
      value: type,
    });
  }
};

onMounted(fetchCustomerTypes);

const isFloatingLabel = computed(() => {
  return isBusinessCustomerComputed.value !== "";
});
</script>
