<template>
  <div class="flex flex-col space-y-6 max-w-6xl mx-auto">
    <div class="flex items-center space-x-8 mb-4">
      <div class="flex items-center space-x-2">
        <Checkbox v-model="importOptions.overwriteExisting" :binary="true" inputId="overwrite" />
        <label for="overwrite">{{ t("product.import.options.has-headers") }}</label>
      </div>
      <div class="flex items-center space-x-2">
        <Checkbox v-model="importOptions.createNew" :binary="true" inputId="createNew" />
        <label for="createNew">{{ t("product.import.options.create-new") }}</label>
      </div>
      <div class="flex items-center space-x-2">
        <Checkbox v-model="importOptions.updateEmpty" :binary="true" inputId="updateEmpty" />
        <label for="updateEmpty">{{ t("product.import.options.update-existing") }}</label>
      </div>

      <div class="ml-auto">
        <span class="mr-2">{{ t("product.import.options.product-identifier") }}</span>
        <Select
          v-model="selectedIdentifier"
          :options="systemFields.sort()"
          optionLabel="label"
          :placeholder="t('product.import.options.product-number-placeholder')"
          class="w-48"
        />
      </div>
    </div>
  </div>

  <DataTable :value="tableData" class="p-datatable-sm" scrollable scrollHeight="400px" :resizableColumns="true">
    <Column v-for="column in columns" :key="column" :field="column" :style="columnStyles[column]">
      <template #header>
        <div class="column-header flex flex-col gap-2">
          <div class="text-sm text-gray-600 font-medium truncate mb-2">
            {{ column }}
          </div>
          <Select
            v-model="getColumnMapping(column).systemField"
            :options="filteredSystemFields(column)"
            optionLabel="label"
            optionValue="value"
            :placeholder="t('product.import.column-mapping.select-field-placeholder')"
            class="w-full"
            :filter="true"
            :filterPlaceholder="t('product.import.column-mapping.search-placeholder')"
            appendTo="body"
          />
        </div>
      </template>
      <template #body="{ data }">
        <div class="cell-content">
          {{ data[column] }}
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { ColumnMapping } from "@/product/views/ProductImport.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const selectedIdentifier = ref<string>("productNumber");
const { t } = useI18n();

const columnMappings = defineModel<ColumnMapping[]>("columnMappings", {
  required: true,
});

const props = defineProps<{
  columns: string[];
  rows: string[][];
}>();

const getColumnMapping = (column: string) => {
  let mapping = columnMappings.value.find((m) => m.importColumn === column);
  if (!mapping) {
    mapping = { importColumn: column, systemField: "" };
    columnMappings.value.push(mapping);
  }
  return mapping;
};

const tableData = computed(() => {
  if (!props.columns.length || !props.rows.length) return [];

  return props.rows.map((row) => {
    const rowData: Record<string, string> = {};
    props.columns.forEach((col, index) => {
      rowData[col] = row[index];
    });
    return rowData;
  });
});

const columnStyles = computed(() => {
  return props.columns.reduce(
    (acc, column) => {
      acc[column] = { minWidth: "200px", maxWidth: "200px" };
      return acc;
    },
    {} as Record<string, { minWidth: string; maxWidth: string }>,
  );
});

const filteredSystemFields = computed(() => {
  return (currentColumn: string) => {
    const selectedValues = columnMappings.value
      .filter((mapping) => mapping.importColumn !== currentColumn && mapping.systemField !== "")
      .map((mapping) => mapping.systemField);

    return systemFields
      .filter((field) => !selectedValues.includes(field.value))
      .sort((a, b) => a.label.localeCompare(b.label));
  };
});

const importOptions = ref({
  overwriteExisting: true,
  createNew: true,
  updateEmpty: false,
});

const systemFields = [
  // Basic Information
  { label: "Produktnummer", value: "productNumber", required: true },
  { label: "Navn", value: "name", required: true },
  { label: "GTIN/EAN", value: "gtin", required: false },
  { label: "Beskrivelse", value: "description", required: false },
  { label: "Produktinformasjon", value: "productInfo", required: false },

  // Classifications
  { label: "Produkttype", value: "productType", required: true },
  { label: "Produktstatus", value: "productStatus", required: true },
  { label: "Kontogruppe", value: "accountGroupId", required: false },

  // Product groups
  { label: "Produktgruppe 1", value: "group1", required: true },
  { label: "Produktgruppe 2", value: "group2", required: false },
  { label: "Produktgruppe 3", value: "group3", required: false },
  { label: "Produktgruppe 4", value: "group4", required: false },
  { label: "Produktgruppe 5", value: "group5", required: false },
  { label: "Produktgruppe språk", value: "productGroupLanguage", required: false },

  // Dimensions and Weight
  { label: "Vektverdi", value: "weight.value", required: false },
  { label: "Vektenhet", value: "weight.unit", required: false },
  { label: "Lengde", value: "dimension.length", required: false },
  { label: "Bredde", value: "dimension.width", required: false },
  { label: "Høyde", value: "dimension.height", required: false },
  { label: "Dimensjonsenhet", value: "dimension.unit", required: false },

  // References
  { label: "Produsent", value: "manufacturerName", required: false },
  { label: "Salgsenhet", value: "salesUnitId", required: false },
  { label: "Alternative produktnumre", value: "alternativeProductIds", required: false },
  { label: "Tilbehørsprodukter", value: "accessoryProductIds", required: false },

  // Locations
  { label: "Klient navn", value: "clientIds", required: false },
  { label: "Lager navn", value: "warehouseIds", required: false },

  // Web
  { label: "Aktiv på nettbutikk", value: "activeOnWebshop", required: false },

  { label: "Mva", value: "vat", required: false },
  { label: "Aktiv", value: "active", required: false },

  // Supplier cost price
  { label: "Leverandørnavn", value: "supplierPrices.name", required: false },
  { label: "Leverandørnummer", value: "supplierPrices.number", required: false },
  { label: "Leverandør kostpris", value: "supplierPrices.costPrice", required: false },
  { label: "Leverandør tollpris", value: "supplierPrices.customs", required: false },
  { label: "Leverandør fraktpris", value: "supplierPrices.freightPrice", required: false },
  { label: "Leverandør nettopris", value: "supplierPrices.netPrice", required: false },
  { label: "Leverandør valuta", value: "supplierPrices.currency", required: false },

  // Prisgrupper
  { label: "Prisgruppe", value: "priceGroup", required: false },
  { label: "Prisgruppe pris", value: "priceGroupPrice", required: false },
  { label: "Prisgruppe valuta", value: "priceGroupCurrency", required: false },

  // Arrays (these might need special handling)
  { label: "Produktbilder", value: "productImages", required: false, isArray: true },
  { label: "Media", value: "media", required: false, isArray: true },
  { label: "Priser", value: "prices", required: false, isArray: true },
  { label: "Attributter", value: "attributes", required: false, isArray: true },
  { label: "Avgifter", value: "taxes", required: false, isArray: true },
  { label: "Dokumenter", value: "documents", required: false, isArray: true },
];
</script>
