<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('order-ready-for-picking.header')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      :value="ordersReadyForPicking"
      dataKey="orderId"
      :autoLayout="true"
      responsiveLayout="scroll"
      breakpoint="999px"
      selectionMode="multiple"
      class="c-datatable"
      :loading="loading"
      contextMenu
      :contextMenuSelection="selectedOrdersReadyForPickingComputed"
      @row-contextmenu="onRowContextMenu"
      v-model:selection="selectedOrdersReadyForPickingComputed"
      :paginator="true"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      data-testid="order-ready-for-picking-list-table"
      ref="ordersReadyForPickingRef"
      :lazy="true"
      stripedRows
      :totalRecords="totalHits"
      :sortOrder="sortOrder"
      :sortField="sortField"
      @sort="onSort"
      @page="onPage"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumnExt[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :selectionMode="col.selectionMode"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template v-if="col.field != OrderReadyForPickingListColumn.SelectPickingList" #body="{ data, field }">
          <template v-if="col.field === OrderReadyForPickingListColumn.ShippingDate">
            {{ d(data.shippingDate, "short") }}
          </template>

          <template v-else-if="col.field === OrderReadyForPickingListColumn.OrderNumber">
            {{ data.orderNumber }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.CustomerNumber">
            {{ data.customerNumber }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.CustomerName">
            {{ data.customerName }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.OrderReference">
            {{ data.orderReference }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.FreightMethodName">
            {{ data.freightMethodName }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.QuantityOfOrderLines">
            {{ data.quantityOfOrderLines }}
          </template>
          <template v-else-if="col.field === OrderReadyForPickingListColumn.SumTotalLines">
            {{ data.sumTotalLines }}
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>
      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("order-ready-for-picking.no-orders-ready-for-picking-found") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
  <ContextMenu :model="menuModel" ref="contextMenu" />
</template>
<script setup lang="ts">
import { OrderReadyForPicking } from "@/repositories/search/model/order/OrderReadyForPicking";
import {
  DataTablePageEvent,
  DataTableRowContextMenuEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { OrderReadyForPickingListColumn } from "./model/OrderReadyForPickingListColumn";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumnExt } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();
const { t, d } = useI18n();

const visible = ref(false);

const props = defineProps<{
  ordersReadyForPicking: OrderReadyForPicking[];
  selectedOrdersReadyForPicking: OrderReadyForPicking[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:page", value: number): void;
  (e: "createPickingLists"): void;
  (e: "selectAll"): void;
  (e: "cancel"): void;
  (e: "update:selectedOrdersReadyForPicking", value: OrderReadyForPicking[]): void;
  (e: "update:pageSize", value: number): void;
  (e: "refreshList"): void;
}>();

const contextMenu = ref();

const ordersReadyForPickingSearchColumns: DataTableColumnExt[] = [
  {
    field: "selectPickingList",
    header: "",
    class: "c-col-selectPickingList w-12",
    sortable: false,
    selectionMode: "multiple",
  },
  {
    field: "shippingDate",
    header: t("order-ready-for-picking.shipping-date"),
    sortable: false,
  },
  {
    field: "orderNumber",
    header: t("order-ready-for-picking.orderNumber"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "customer.customerNumber",
    header: t("order-ready-for-picking.customerNumber"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "customer.customerName",
    header: t("order-ready-for-picking.name"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderReference",
    header: t("order-ready-for-picking.orderReference"),
    sortable: true,
  },
  {
    field: "freightMethodName",
    header: t("order-ready-for-picking.freightMethod"),
    sortable: true,
  },
  {
    field: "orderlines",
    header: t("order-ready-for-picking.quantity-of-order-items"),
    class: "text-right",
    sortable: false,
  },
  {
    field: "sumTotalLines",
    header: t("order-ready-for-picking.sumTotalLines"),
    class: "text-right",
    sortable: false,
  },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences(
  "ordersReadyForPickingSearch",
  ordersReadyForPickingSearchColumns,
  null,
  (await getUser()).getEmployee().id,
);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== OrderReadyForPickingListColumn.SelectPickingList &&
      col.field !== OrderReadyForPickingListColumn.ShippingDate &&
      col.field !== OrderReadyForPickingListColumn.OrderNumber,
  );
});

const selectedOrdersReadyForPickingComputed = computed({
  get: () => props.selectedOrdersReadyForPicking,
  set: (value) => emit("update:selectedOrdersReadyForPicking", value),
});

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  const ordersReadyForPicking = [event.data] as OrderReadyForPicking[];

  emit("update:selectedOrdersReadyForPicking", ordersReadyForPicking);
  contextMenu.value.show(event.originalEvent);
};

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickInventoryRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const menuModel = ref([
  {
    label: t("order-ready-for-picking.context-menu.create-picking-lists"),
    icon: "pi pi-fw pi-search",
    command: () => emit("createPickingLists"),
  },
  {
    label: t("order-ready-for-picking.context-menu.select-all"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("selectAll"),
  },
  {
    label: t("order-ready-for-picking.context-menu.cancel"),
    icon: "pi pi-fw pi-times",
    command: () => emit("cancel"),
  },
]);

const onClickInventoryRefresh = () => {
  emit("refreshList");
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? ordersReadyForPickingSearchColumns
    : ordersReadyForPickingSearchColumns.filter(
        (c) =>
          c.field === OrderReadyForPickingListColumn.SelectPickingList ||
          c.field === OrderReadyForPickingListColumn.ShippingDate ||
          c.field === OrderReadyForPickingListColumn.OrderNumber,
      );
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};
</script>
<style scoped lang="scss">
::v-deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
