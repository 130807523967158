import { httpClient, AuthHeaders } from "@cumulus/http";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";
import { NewBringBooking } from "./NewBringBooking";
import { PickupPointReponse } from "./PickupPoint";

class BringApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/bring-integration`
        : `${import.meta.env.VITE_APP_API_URL as string}/bring-integration`;
  }

  public async getBringShippingGuide(authHeaders: AuthHeaders, data: BringShippingGuideRequest) {
    return await httpClient(this.uri, authHeaders)
      .post("/shipping-guides", data)
      .then(({ data }) => data);
  }

  public async storeBringBookingData(
    authHeaders: AuthHeaders,
    body: NewBringBooking,
  ): Promise<StoreBringBookingResponse> {
    return await httpClient(this.uri, authHeaders)
      .post(`/store-booking`, body)
      .then(({ data }) => data);
  }

  public async getPickupPoints(
    authHeaders: AuthHeaders,
    countryCode: string,
    postalCode: string,
    street: string,
    streetNumber: string,
  ): Promise<PickupPointReponse> {
    return await httpClient(this.uri, authHeaders)
      .get(`/pickup-points/${countryCode}/postal-code/${postalCode}`, { params: { street, streetNumber } })
      .then(({ data }) => data);
  }
}

interface StoreBringBookingResponse {
  bringBookingId: string;
  url: string;
}

const bringApi = new BringApi();
export { bringApi };
