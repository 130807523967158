<template>
  <FloatLabelDropdownPanel
    id="shipping-price-panel"
    :selectedItemName="shippingPrice && shippingPrice > 0 ? shippingPrice : t('order.shipping-price-empty')"
    :label="t('order.shipping-price')"
    :selectLabel="t('order.customer.select')"
    dataTestId="shipping-price-panel"
    class="c-shipping-price-dropdown"
    :class="{ 'p-disabled': !allowEdit }"
    :disabled="!allowEdit"
    :setFocus="focusInput"
    @toggleDropdownPanel="showPanel"
  />

  <ShippingPricePanel
    ref="shippingPricePanelRef"
    v-model:shippingPrice="shippingPrice"
    v-model:agreedFreight="agreedFreight"
    :currencyIso="props.currencyIso"
    :freightMethod="props.freightMethod"
    @shippingCalculationDialogVisible="emit('shippingCalculationDialogVisible', $event)"
    @closeShippingPricePanel="focusInput = true"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ShippingPricePanel from "./ShippingPricePanel.vue";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderFreightMethod } from "@/models/order/OrderFreightMethod";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  orderStatus?: OrderStatus;
  freightMethod: OrderFreightMethod;
}>();

const emit = defineEmits<{
  (e: "shippingCalculationDialogVisible", value: boolean): void;
}>();

const shippingPrice = defineModel<number>("shippingPrice", { required: true });
const agreedFreight = defineModel<boolean>("agreedFreight", { required: true });

const focusInput = ref(false);

const shippingPricePanelRef = ref();
const showPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  focusInput.value = false;

  shippingPricePanelRef.value.toggle(event);
  event.preventDefault();
};

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-shipping-price-dropdown {
  width: 100%;
}
</style>
