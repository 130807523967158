<template>
  <div class="mr-6">
    <PrimeMenu ref="addressMenu" id="overlay_address_menu" :model="addressMenuOptions" :popup="true">
      <template #item="{ item, props }">
        <a
          class="flex items-center"
          v-bind="props.action"
          :data-testid="item.dataTestId"
          :disabled="item.disabled"
          :class="item.class"
        >
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <span
            v-if="item.shortcut"
            class="ml-auto border border-surface rounded-border bg-surface-100 dark:bg-surface-700 text-xs p-1"
            >{{ item.shortcut }}</span
          >
        </a>
      </template></PrimeMenu
    >

    <Tabs class="c-tabview" value="0" data-testid="information-tab">
      <TabList>
        <Tab value="0" data-testid="tab-supplier">{{ t(`purchase.info-tabs.first-tab`) }}</Tab>
        <Tab value="1" data-testid="tab-delivery">{{ t(`purchase.info-tabs.second-tab`) }}</Tab>
        <div class="flex justify-end w-full">
          <PrimeButton
            text
            type="button"
            icon="pi pi-ellipsis-h"
            @click="toggleAddressMenu"
            aria-haspopup="true"
            aria-controls="overlay_address_menu"
            tabindex="-1"
            data-testid="info-menu-button"
          />
        </div>
      </TabList>

      <TabPanels>
        <TabPanel value="0" tabindex="-1">
          <div>
            <table>
              <tbody>
                <tr>
                  <td class="w-full text-sm" v-if="supplier.name">
                    <span data-testid="supplier-name">{{ supplier.name }}</span> /
                    <span data-testid="supplier-number">{{ supplier.supplierNumber }}</span>
                  </td>
                  <td class="w-full text-sm" v-else>
                    <span>
                      {{ t(`purchase.supplier.no-supplier`) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    class="w-full text-sm whitespace-nowrap overflow-hidden text-ellipsis"
                    data-testid="supplier-address"
                  >
                    <span v-for="(line, index) in supplier.address.addressLines" :key="line">
                      {{ index > 0 ? ", " : "" }}{{ line }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="w-full text-sm">
                    <span data-testid="supplier-postal-code" class="mr-1">{{ supplier.address.postalCode }}</span>
                    <span data-testid="supplier-postal-city">{{ supplier.address.city }}</span>
                  </td>
                </tr>

                <tr>
                  <td class="w-full text-sm" v-if="supplier.phoneNumber">
                    <span>{{ t(`purchase.supplier.phone`, { symbol: ": " }) }}</span
                    ><span data-testid="supplier-phone-number">{{ supplier.phoneNumber }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="w-full text-sm" v-if="supplier.email">
                    <span>{{ t(`purchase.supplier.email`, { symbol: ": " }) }}</span>
                    <span data-testid="supplier-email">{{ supplier.email }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>

        <TabPanel value="1" tabindex="-1">
          <table>
            <tbody>
              <tr>
                <td class="w-full text-sm" data-testid="delivery-name" v-if="props.delivery.name">
                  {{ props.delivery.name }}
                </td>
                <td class="w-full text-sm" v-else>
                  <span>
                    {{ t(`purchase.no-warehouse`) }}
                  </span>
                </td>
              </tr>

              <tr>
                <td class="w-full text-sm" data-testid="delivery-address">
                  <span v-for="(line, index) in props.delivery.address.addressLines" :key="line">
                    {{ index > 0 ? ", " : "" }}{{ line }}</span
                  >
                </td>
              </tr>
              <tr>
                <td class="w-full text-sm" data-testid="delivery-postal-code">
                  <span class="mr-1">{{ props.delivery.address.postalCode }}</span>
                  <span>{{ props.delivery.address.city }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
      </TabPanels>
    </Tabs>

    <EditDeliveryInformationDialog
      v-if="showDialog"
      v-model:showDialog="showDialog"
      :delivery="delivery"
      :countries="countryOptions"
      @updateDeliveryInformation="updateDeliveryInformation"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCountry } from "@/api/country/CountryService";
import { Country } from "@/models/country/Country";
import EditDeliveryInformationDialog from "../EditDeliveryInformationDialog.vue";
import { PurchaseOrderDelivery } from "@/models/purchase-order/PurchaseOrderDelivery";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import type { MenuItem } from "primevue/menuitem";

const props = defineProps<{
  delivery: PurchaseOrderDelivery;
  supplier: PurchaseOrderSupplier;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const { t } = useI18n();

const addressMenu = ref();
const addressMenuOptions = ref([] as MenuItem[]);

const createMenu = () => {
  return [
    {
      label: t(`purchase.info-tabs.menu-change-delivery-address`),
      icon: "pi pi-pencil",
      command: () => {
        showDialog.value = true;
      },
      dataTestId: "menu-item-change-delivery-address",
      disabled: !allowEdit.value,
      class: !allowEdit.value ? "p-disabled" : "",
    },
  ];
};

const toggleAddressMenu = (event: Event) => {
  addressMenuOptions.value = createMenu();
  addressMenu.value.toggle(event);
};

const emit = defineEmits<{
  (e: "update:delivery", value: PurchaseOrderDelivery): void;
}>();

const { getAllCountries } = useCountry();

const showDialog = ref<boolean>(false);
const countryOptions = ref<Country[]>([]);

const updateDeliveryInformation = (delivery: PurchaseOrderDelivery) => {
  emit("update:delivery", delivery);
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

onMounted(() => {
  fetchCountryData();
});

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>
<style lang="scss" scoped>
:deep(.c-tabview).p-tabview {
  padding-top: 0.12rem;
}
:deep(.c-tabview).p-tabview .p-tabview-nav {
  border-color: transparent;
}

:deep(.c-tabview).p-tabview .p-tabview-panels {
  padding-bottom: 0;
}

:deep(.c-tabview).p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}

.c-description {
  color: var(--text-color-secondary);
}
</style>
