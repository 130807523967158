<template>
  <div class="mx-3">
    <FloatLabel variant="on">
      <Select
        id="product-type"
        :options="dropdownOptions"
        optionLabel="name"
        optionValue="value"
        data-testid="product-type"
        v-model="selectedProductType"
        class="w-full"
        pt:list:data-testid="product-type-list"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('product.type.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />

      <label for="product-type">
        {{ t(`product.type.label`) }}
      </label>
    </FloatLabel>
    <small id="product-type-help" class="p-error" v-if="val.productType.$error">
      {{ val.productType.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, WritableComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { ProductType } from "@/repositories/product/model/ProductType";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  productType: string;
}>();

const emit = defineEmits<{
  (e: "update:productType", type: string): void;
}>();

const { t } = useI18n();
const languageKey = "product.type.";

const selectedProductType: WritableComputedRef<string> = computed({
  get: () => {
    return props.productType ?? "";
  },
  set: (value) => {
    emit("update:productType", value);
  },
});

const productTypes = ref<Record<string, string>[]>([]);

const rules = {
  productType: {
    required: required,
  },
};

const val = useValidate(rules, props);

const fetchStatusTypes = () => {
  for (const prType in ProductType) {
    productTypes.value.push({
      label: t(`${languageKey}${prType.toLowerCase()}`),
      value: prType,
    });
  }
  if (selectedProductType.value === "" && productTypes.value.length > 0) {
    selectedProductType.value = productTypes.value[0].code;
  }
};
onMounted(fetchStatusTypes);

const dropdownOptions = computed(() => {
  return productTypes.value.map((productType) => {
    return { name: productType.label, value: productType.value };
  });
});
</script>
<style scoped lang="scss">
label {
  color: #999;
  top: -2px;
}
</style>
