import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { useErrorHandler } from "../ErrorHandler";
import { CustomerSearchRequest } from "../../models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "../../models/search/customer/CustomerSearchResponse";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { ProductSearchResponse } from "@/models/search/product/ProductSearchResponse";

export const useSearch = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  let customerRequest: CustomerSearchRequest | null = null;
  let customerResponse: CustomerSearchResponse | null = null;

  const loadingCustomer = ref(false);

  const customerSearch = async (
    request: CustomerSearchRequest,
    clearCache = false
  ): Promise<CustomerSearchResponse> => {
    try {
      loadingCustomer.value = true;

      if (clearCache) {
        customerRequest = null;
        customerResponse = null;
      }
      const authHeaders = await getAuthHeaders();
      if (customerRequest && customerRequest?.equals(request) && customerResponse !== null) {
        return customerResponse;
      }

      customerResponse = await searchApi.customerSearch(authHeaders, request);
      customerRequest = request;
      return customerResponse;
    } catch (error) {
      await handleError(error);
      return new CustomerSearchResponse();
    } finally {
      loadingCustomer.value = false;
    }
  };

  let productSearchRequest: ProductSearchRequest | null = null;
  let productSearchResponse: ProductSearchResponse | null = null;
  const loadingProduct = ref(false);

  const productSearch = async (request: ProductSearchRequest, clearCache = false): Promise<ProductSearchResponse> => {
    try {
      loadingProduct.value = true;
      if (clearCache) {
        productSearchRequest = null;
        productSearchResponse = null;
      }

      const authHeaders = await getAuthHeaders();

      if (productSearchRequest && productSearchRequest?.equals(request) && productSearchResponse !== null) {
        return productSearchResponse;
      }
      productSearchResponse = (await searchApi.productsSearch(authHeaders, request)) as ProductSearchResponse;
      productSearchRequest = request;
      return productSearchResponse;
    } catch (error) {
      await handleError(error);
    } finally {
      loadingProduct.value = false;
    }

    return {
      products: [],
      totalHits: 0,
    } as ProductSearchResponse;
  };

  return { customerSearch, loadingCustomer, productSearch, loadingProduct };
};
