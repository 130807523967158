<template>
  <FilterOverview v-model:filters="filtersComputed" :customer-groups="customerGroups" />
</template>

<script setup lang="ts">
import { SearchFilters } from "@/models/search/customer/SearchFilters";
import FilterOverview from "./FilterOverview.vue";
import { computed } from "vue";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";

const props = defineProps<{
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
}>();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: SearchFilters) {
    emit("update:filters", value);
  },
});
</script>
