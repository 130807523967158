import { httpClient, AuthHeaders } from "@cumulus/http";
import { Customer } from "./model/Customer";

class CustomerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != "undefined" && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER + "/customers"
        : `${import.meta.env.VITE_APP_API_URL as string}/customers`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Customer> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }
}

const customerApi = new CustomerApi();
export { customerApi };
