<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <EmployeeHeader
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :isSaving="isSaving"
    @stayOnPage="stayOnPage"
    @onCommit="onCreate"
    @onCancel="onCancel"
    @routeToEmployeeSearch="routeToEmployeeSearch"
  />
  <div class="c-add-employee">
    <Message v-for="employee of employeesCreated" severity="info" :key="employee.id"
      >{{ t("employee.created-message")
      }}<a class="employee-created-link" :href="`/system-data/employee/${employee.id}`" :closable="true">{{
        employee.firstName + " " + employee.lastName
      }}</a></Message
    >

    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <FirstName v-model:firstName="employee.firstName" ref="employeeFirstnameRef" />
              </div>
              <div class="mb-4">
                <LastName v-model:lastName="employee.lastName" />
              </div>
              <div class="mb-4 md:mb-0">
                <Initials v-model:initials="employee.initials" />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Email v-model:email="employee.email" />
              </div>
              <div class="mb-4">
                <Language v-model:languageCode="employee.languageCode" />
              </div>
              <div class="mb-4 md:mb-0">
                <PhoneNumber v-model:phoneNumber="employee.phoneNumber" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <Role v-model:role="employee.roles[0]" :roleOptions="roleOptions" />
              </div>

              <div class="mb-4">
                <EmployeeState v-model:employeeState="employee.employeeState" />
              </div>

              <div class="grid grid-cols-12 gap-4 mb-4 md:mb-0">
                <div class="col-span-12 lg:col-span-6 lg:pr-4">
                  <Date
                    id="start-date"
                    :label="t('employee.start-date')"
                    :placeholder="t('placeholder.select', { property: t('employee.start-date').toLowerCase() })"
                    v-model:date="employee.startDate"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <Date
                    id="deactivation-date"
                    :label="t('employee.deactivation-date')"
                    :placeholder="t('placeholder.select', { property: t('employee.deactivation-date').toLowerCase() })"
                    v-model:date="employee.deactivationDate"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <div class="col-span-12 lg:col-span-8 mb-6">
        <Panel
          :header="t('employee.panels.prefrence')"
          toggleable
          class="mb-4"
          id="prefrencesPanel"
          data-testid="prefrences-panel"
          :collapsed="panelCollapsedPrefrence"
          :pt:header:onClick="() => (panelCollapsedPrefrence = !panelCollapsedPrefrence)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Clients v-model:clients="employee.clients" :clientOptions="clientOptions" />
              </div>
              <div class="mb-4 md:mb-0">
                <DefaultClient
                  v-model:defaultClientId="employee.defaultClientId"
                  :clientOptions="clientOptions.filter((client) => employee.clients.includes(client.id))"
                />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4 md:mb-0">
                <DefualtWarehouse
                  v-model:defaultWarehouseId="employee.warehouseId"
                  :warehouseOptions="warehouseOptions"
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          :header="t('employee.panels.theme')"
          toggleable
          :collapsed="panelCollapsedTheme"
          id="themePanel"
          data-testid="theme-panel"
          :pt:header:onClick="() => (panelCollapsedTheme = !panelCollapsedTheme)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <DataTableLayout v-model:dataTabelStyle="employee.preferences.dataTableStyle" />
              </div>

              <div class="mb-4">
                <InputLayout v-model:inputStyle="employee.preferences.inputStyle" />
              </div>
              <div class="mb-4">
                <HeaderLayout v-model:headerStyle="employee.preferences.headerStyle" />
              </div>
              <div class="mb-4 md:mb-0">
                <MenuLayout v-model:menuStyle="employee.preferences.menuStyle" />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import isEqual from "lodash.isequal";

import { NewEmployee } from "@/repositories/employee/model/NewEmployee";
import { Client } from "@/repositories/employee/client/model/Client";
import { Role as RoleModel } from "@/repositories/employee/role/model/Role";
import { Warehouse } from "@/repositories/employee/model/Warehouse";

import { useEmployee } from "@/repositories/employee/EmployeeService";

import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Initials from "../components/Initials.vue";
import Email from "../components/Email.vue";
import Clients from "../components/Clients.vue";
import DefaultClient from "../components/DefaultClient.vue";
import Language from "../components/Language.vue";
import Role from "../components/Role.vue";
import EmployeeHeader from "../components/EmployeeHeader.vue";
import Date from "../components/Date.vue";
import DefualtWarehouse from "../components/DefualtWarehouse.vue";
import EmployeeState from "../components/EmployeeState.vue";
import DataTableLayout from "../components/DataTableLayout.vue";
import InputLayout from "../components/InputLayout.vue";
import HeaderLayout from "../components/HeaderLayout.vue";
import MenuLayout from "../components/MenuLayout.vue";

const { createEmployee, getClients, getWarehouses } = useEmployee();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const employee = ref(new NewEmployee());
const initialEmployee = ref<NewEmployee>(new NewEmployee());
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const isSaving = ref(false);
const employeesCreated = ref<NewEmployee[]>([]);
const confirmedDiscard = ref(false);

const panelCollapsedPrefrence = ref(false);
const panelCollapsedTheme = ref(false);

onMounted(() => {
  employee.value.languageCode = "NO";
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToEmployeeSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "employee-search" });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const val = useValidate();

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  isSaving.value = true;
  try {
    await createEmployee(employee.value);

    employeesCreated.value.push(employee.value);
    employee.value = new NewEmployee();
    initialEmployee.value = new NewEmployee();
    val.value.$reset();
    window.scrollTo(0, 0);
    focusFirstInput();
  } finally {
    isSaving.value = false;
  }
};

const clientOptions = ref<Client[]>([]);
const fetchClientOptions = async () => {
  clientOptions.value = await getClients();
};

const roleOptions = ref<RoleModel[]>([]);
const fetchRoleOptions = async () => {
  roleOptions.value = [
    new RoleModel("reader", t(`common.role.reader`)),
    new RoleModel("contributor", t(`common.role.contributor`)),
    new RoleModel("admin", t(`common.role.admin`)),
  ];
};

const warehouseOptions = ref<Warehouse[]>([]);
const fetchWarehouseOptions = async () => {
  warehouseOptions.value = await getWarehouses();
};

const employeeFirstnameRef = ref();
const focusFirstInput = () => {
  employeeFirstnameRef.value.focus();
};

onMounted(focusFirstInput);

const hasUpdates = computed(() => {
  return !isEqual(employee.value, initialEmployee.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToEmployeeSearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToEmployeeSearch();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([focusFirstInput(), fetchWarehouseOptions(), fetchRoleOptions(), fetchClientOptions()]);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

useShortcut(ShortcutAction.save, onCreate);
</script>

<style scoped lang="scss">
.c-add-employee {
  margin: var(--default-content-margin);
}
</style>
