import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { ManufacturerStatus } from "./ManufacturerStatus";
import { NIL as emptyUuid } from "uuid";

export class Manufacturer {
  id = emptyUuid;
  companyId = "";
  name = "";
  description = "";
  logo: ImageInfo = new ImageInfo();
  websiteUrl = "";
  manufacturerStatus = ManufacturerStatus.Active;

  constructor(manufacturer: Manufacturer | null = null) {
    if (manufacturer === null) {
      return;
    }

    this.id = manufacturer.id;
    this.companyId = manufacturer.companyId;
    this.name = manufacturer.name;
    this.description = manufacturer.description;
    this.logo = manufacturer.logo;
    this.websiteUrl = manufacturer.websiteUrl;
    this.manufacturerStatus = manufacturer.manufacturerStatus;
  }
}
