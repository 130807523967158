<template>
  <div class="flex items-center gap-2 mb-4">
    <div class="flex-1 text-color font-semibold leading-6">
      <PrimeButton
        id="btn-commit"
        class="c-circular-button mr-4"
        data-testid="btn-commit"
        style="border-color: var(--p-button-outlined-secondary-border-color)"
        @click="router.push({ name: 'order-add' })"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4 font-normal">{{ t("common.add") }}</span>
      </PrimeButton>
    </div>
    <div>
      <PrimeButton
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="outlined"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton data-testid="c-refresh-button" severity="secondary" variant="outlined" @click="emit('refreshList')">
        <i class="pi pi-refresh"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="outlined"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton
        data-testid="filter-button"
        severity="secondary"
        variant="outlined"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "onToggleFilterSidebar"): void;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });

const router = useRouter();
const { t } = useI18n();
</script>
