import { httpClient, AuthHeaders } from "@cumulus/http";

class ProductImportApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async initializeImport(authHeaders: AuthHeaders, file: FormData): Promise<ProductImportInitializeResponse> {
    return await httpClient(this.uri, authHeaders)
      .postForm("/import/initialize", file)
      .then(({ data }) => data);
  }
}

export interface ProductImportInitializeResponse {
  id: string;
  columns: string[];
  rows: string[][];
}

export const productImportApi = new ProductImportApi();
