<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <div class="c-supplier-add mb-20 m-10">
    <SupplierHeader
      :editMode="false"
      :hasUpdates="hasUpdates"
      :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
      @dialogCancelBtnClicked="stayOnPage"
      @dialogDiscardBtnClicked="routeToSupplierList"
      :collapsedPanels="panelCollapsed"
      @onToggleAllPanels="onToggleAllPanels()"
    />
    <div class="col-span-12">
      <div class="flex mb-4">
        <div class="w-1/2">
          <PrimeButton
            icon="pi pi-arrow-left"
            variant="outlined"
            class="text-black bg-white"
            @click="onCancel"
            data-testid="btn-cancel"
            id="btn-cancel"
          />
        </div>

        <div class="w-1/2 text-right">
          <PrimeButton
            :label="t('common.save')"
            variant="outlined"
            icon="pi pi-check"
            iconPos="right"
            severity="secondary"
            iconClass="ml-2"
            class="bg-white mr-4 px-4 py-1"
            data-testid="btn-save"
            id="btn-save"
            @click="addSupplier"
          />

          <PrimeButton
            :label="panelCollapsed ? t('supplier.panel.open-panels') : t('supplier.panel.close-panels')"
            variant="outlined"
            icon="pi pi-clone"
            iconPos="right"
            severity="secondary"
            iconClass="ml-2"
            class="bg-white mr-4 px-4 py-1"
            @click="onToggleAllPanels"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 my-6">
      <Card class="col-span-12 lg:col-span-8 mr-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <SupplierNumber v-model:supplierNumber="supplier.supplierNumber" :isEdit="false" :focus-input="true" />
              </div>

              <div class="mb-4">
                <Country v-model:countryIso="supplier.countryIso" :countryOptions="countryOptions" />
              </div>

              <div class="mb-4 md:mb-0">
                <DocumentLanguage v-model:documentLanguage="supplier.documentLanguage" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Name v-model:name="supplier.name" ref="supplierNameRef" />
              </div>

              <div class="mb-4">
                <Email v-model:email="supplier.email" />
              </div>
              <div class="mb-4 md:mb-0">
                <Clients v-model:selectedClientIds="supplier.clientIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <BusinessNumber v-model:businessNumber="supplier.businessNumber" />
              </div>

              <div>
                <PhoneNumber v-model:phoneNumber="supplier.phoneNumber" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('supplier.addresses.label')"
      toggleable
      :collapsed="panelCollapsedAddress"
      class="my-10 rounded-2xl"
      id="addressPanelCollapsed"
      :pt:header:onClick="() => (panelCollapsedAddress = !panelCollapsedAddress)"
    >
      <div class="grid grid-cols-12 gap-4 mb-4">
        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4" data-testid="company-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.company-address") }}</label>
          <Address
            :selectedCountry="supplier.countryIso"
            :address="supplier.companyAddress"
            :countryOptions="countryOptions"
            @setAddress="setCompanyAddress"
            @autoSelectCountry="autoSelectedCountry"
            :address-type="addressTypeCompany"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4" data-testid="return-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.return-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.returnAddress"
            :countryOptions="countryOptions"
            @setAddress="setReturnAddress"
            :address-type="addressTypeReturn"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4" data-testid="invoice-address">
          <label class="inline-block mb-3 font-semibold">
            {{ t("supplier.addresses.invoice-address") }}
          </label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.invoiceAddress"
            :countryOptions="countryOptions"
            @setAddress="setInvoiceAddress"
            :address-type="addressTypeInvoice"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('supplier.contacts.header')"
      toggleable
      :collapsed="panelCollapsedContact"
      class="my-10 rounded-2xl"
      id="contactsPanelCollapsed"
      :pt:header:onClick="() => (panelCollapsedContact = !panelCollapsedContact)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <ContactList v-model:contacts="supplier.contacts" />
        </div>
      </div>
    </Panel>

    <div class="grid grid-cols-12 gap-10 mb-4">
      <div class="col-span-12 lg:col-span-8 mb-6">
        <Panel
          :header="t('supplier.condition-and-freight')"
          toggleable
          class="rounded-2xl"
          :collapsed="panelCollapsedConditionFreight"
          id="conditionsAndFreightPanelCollapsed"
          :pt:header:onClick="() => (panelCollapsedConditionFreight = !panelCollapsedConditionFreight)"
        >
          <div class="grid grid-cols-12 gap-4 mb-4 lg:min-h-[285px]">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Currency v-model:currency="supplier.currency" />
              </div>
              <div class="mb-4">
                <DefaultPaymentTerm v-model:paymentTermId="supplier.defaultPaymentTermId" />
              </div>
              <div class="mb-4">
                <Incoterms v-model:incoterms="supplier.incoterms" />
              </div>
              <div class="mb-4">
                <EstimatedDeliveryDays v-model:estimatedDeliveryDays="supplier.estimatedDeliveryDays" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <FreightMethod v-model:freightMethodId="supplier.freightMethodId" />
              </div>
              <div class="mb-4">
                <FixedShipping v-model:fixedShipping="supplier.fixedShipping" />
              </div>
              <div class="mb-4">
                <FreeShipping v-model:freeFreight="supplier.freeShippingFrom" />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          :header="t('supplier.documents.header')"
          toggleable
          :collapsed="panelCollapseDocuments"
          id="conditionsDocumentsPanelCollapsed"
          data-testid="documents-panel"
          class="rounded-2xl"
          :pt:header:onClick="() => (panelCollapseDocuments = !panelCollapseDocuments)"
        >
          <div class="grid grid-cols-12 gap-4 lg:min-h-[298px]">
            <div class="col-span-12">
              <Documents v-model:documents="supplier.documents" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, nextTick } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useI18n } from "vue-i18n";

import { Supplier } from "@/repositories/supplier/model/Supplier";
import { Country as CountryModel } from "@/models/country/Country";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { supplierAddressFunctions } from "../SupplierAddressFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useCountry } from "@/api/country/CountryService";
import BusinessNumber from "../components/BusinessNumber.vue";
import SupplierHeader from "../components/SupplierHeader.vue";
import FreightMethod from "../components/FreightMethod.vue";
import Country from "../components/Country.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Address from "../components/Address.vue";
import Incoterms from "../components/Incoterms.vue";
import Email from "../components/Email.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import ContactList from "../components/ContactList.vue";
import SupplierNumber from "../components/SupplierNumber.vue";
import Name from "../components/Name.vue";
import Currency from "../components/Currency.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import Clients from "../components/Clients.vue";
import FixedShipping from "../components/FixedShipping.vue";
import FreeShipping from "../components/FreeShipping.vue";
import EstimatedDeliveryDays from "../components/EstimatedDeliveryDays.vue";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useAuth } from "@cumulus/event-bus";
import { useClient } from "@/api/client/ClientService";
import { useCurrency } from "@/api/currency/CurrencyService";
import Documents from "../components/Documents.vue";

const { t } = useI18n();
const router = useRouter();
const toast = useCumulusToast(useToast());
const { createSupplier } = useSupplier();
const { getAllCountries } = useCountry();
const countryOptions = ref<CountryModel[]>([]);
const addressTypeInvoice = ref("invoice");
const addressTypeReturn = ref("return");
const addressTypeCompany = ref("company");

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);

const supplier = ref<Supplier>(new Supplier());

const initialSupplier = ref<Supplier>(new Supplier());
const { getCurrency } = useCurrency();
const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

onMounted(async () => {
  const defaultContact = new SupplierContact();
  defaultContact.isDefaultContact = true;
  supplier.value.contacts.push(defaultContact);

  const client = await getClient((await getAuthHeaders()).clientId);
  supplier.value.countryIso = client.countryIso;
  supplier.value.currency = await getCurrency(client.currencyIso);
  supplier.value.documentLanguage = client.countryIso;
  initialSupplier.value = cloneDeep(supplier.value);
});

const { setCompanyAddress, setReturnAddress, setInvoiceAddress } = supplierAddressFunctions(supplier);

//Nested validation
const val = useValidate();

const supplierNameRef = ref();
const confirmedDiscard = ref(false);

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const addSupplier = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await createSupplier(supplier.value);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.add-success.summary"),
    detail: t("supplier.toast.add-success.detail", { name: supplier.value.name }),
    closable: true,
  });

  routeToSupplierList();
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const autoSelectedCountry = (countryIso: string) => {
  if (supplier.value.companyAddress.countryIso === "") {
    supplier.value.companyAddress.countryIso = countryIso;
    supplier.value.invoiceAddress.countryIso = countryIso;
    supplier.value.returnAddress.countryIso = countryIso;
  }
};

const panelCollapsedAddress = ref(false);
const panelCollapsedContact = ref(false);
const panelCollapsedConditionFreight = ref(false);
const panelCollapseDocuments = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedAddress.value &&
    panelCollapsedContact.value &&
    panelCollapsedConditionFreight.value &&
    panelCollapseDocuments.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAddress.value = newState;
  panelCollapsedContact.value = newState;
  panelCollapsedConditionFreight.value = newState;
  panelCollapseDocuments.value = newState;
};

const hasUpdates = computed(() => {
  return !isEqual(supplier.value, initialSupplier.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToSupplierList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  focusInput();
  fetchCountryData();
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    if (unsavedChangesDialogVisible.value) {
      routeToSupplierList();
    }
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const focusInput = async () => {
  await nextTick();
  supplierNameRef.value.focus();
};

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<style scoped lang="scss">
.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
