<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12">
      <PrimeButton
        icon="pi pi-arrow-left"
        @click="onCancel"
        data-testid="btn-cancel"
        id="btn-cancel"
        rounded
        size="small"
        variant="text"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800">{{ t("customer.new-customer") }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <PrimeButton class="c-circular-button mr-4" @click="onCreate()" data-testid="btn-commit" id="btn-commit">
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.create") }}</span>
      </PrimeButton>

      <PrimeButton class="c-circular-button" @click="onToggleAllPanels()">
        <i class="pi pi-clone c-default-button c-circular-icon"></i>
        <span class="px-3">{{
          panelCollapsed ? t("customer.panel.open-panels") : t("customer.panel.close-panels")
        }}</span>
      </PrimeButton>
    </div>
  </div>

  <div class="c-page-content mt-2 ml-5 pr-1" data-testid="customer-add">
    <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />

    <UnsavedChangesDialog
      position="top"
      :visible="unsavedChangesDialogVisible"
      :dialogHeader="t('common.unsaved-changes-header')"
      @cancelClicked="onCancel"
      @discardClicked="routeToCustomerList"
      @saveClicked="onCreate"
      ><template #content>{{ t("common.unsaved-changes-text") }}</template>
      <template #cancelBtn>{{ t("common.cancel") }}</template>
      <template #discardBtn>{{ t("common.discard") }}</template>
      <template #saveBtn>{{ t("common.save") }}</template>
    </UnsavedChangesDialog>

    <Message
      v-for="customer of customersCreated"
      severity="info"
      :key="customer.id"
      closable
      icon="pi pi-check"
      class="mb-4"
    >
      {{ t("customer.created-message")
      }}<a :href="`/customer/edit/${customerId}`" class="customer-created-link">
        {{
          customer.customerType === CustomerTypeModel.Business
            ? customer.businessCustomer.companyName
            : customer.privateCustomer.firstName + " " + customer.privateCustomer.lastName
        }}
      </a>
    </Message>
    <div class="sm:flex">
      <div class="w-full sm:w-3/4 mr-6 mb-8">
        <Card class="h-full">
          <template #content>
            <div class="flex-1 mr-4">
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <FirstName
                    v-if="!isBusinessCustomer"
                    v-model:firstName="customer.privateCustomer.firstName"
                    :focusInput="true"
                  />
                  <CompanyName
                    v-if="isBusinessCustomer"
                    v-model:companyName="customer.businessCustomer.companyName"
                    :focusInput="true"
                  />
                </div>
                <div>
                  <OrganizationNumber
                    v-if="isBusinessCustomer"
                    v-model:organizationNumber="customer.businessCustomer.organizationNumber"
                  />
                  <LastName v-if="!isBusinessCustomer" v-model:lastName="customer.privateCustomer.lastName" />
                </div>
                <div>
                  <CustomerEmail v-model:emailAddress="customer.email" />
                </div>
                <div>
                  <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
                </div>
                <div class="mb-2">
                  <CustomerNumber v-model:customerNumber="customer.customerNumber" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="w-full sm:w-1/4 mb-8">
        <Card class="c-card-font h-full">
          <template #content>
            <div class="flex-1">
              <div class="px-2 -mx-4">
                <div class="mt-6 mb-7">
                  <CustomerType v-model:customerType="customer.customerType" />
                </div>

                <div class="mb-6">
                  <CustomerState v-model:customerState="customer.customerState" />
                </div>

                <div class="mb-2">
                  <DocumentLanguage v-model:documentLanguage="customer.documentLanguageIso" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <Panel
      :header="t('common.addresses')"
      toggleable
      :collapsed="panelCollapsedAdresses"
      class="c-card-font mb-4"
      :pt:header:onClick="() => (panelCollapsedAdresses = !panelCollapsedAdresses)"
    >
      <div class="sm:flex mb-4">
        <div class="w2-full flex-1 xl:border-r-2">
          <Address
            :selectedCountry="customer.deliveryAddress.countryIso"
            :address="customer.deliveryAddress"
            :countryOptions="countryOptions"
            @setAddress="setDeliveryAddress"
            @autoSelectCountry="autoSelectedCountry"
            :addressType="addressTypeDelivery"
            :addressLabel="t('customer.address.delivery')"
          />
        </div>
        <div class="w-full flex-1 pl-3">
          <Address
            :selectedCountry="customer.address.countryIso"
            :address="customer.address"
            :countryOptions="countryOptions"
            @setAddress="setCompanyAddress"
            @autoSelectCountry="autoSelectedCountry"
            :addressType="addressTypeInvoice"
            :addressLabel="t('customer.address.invoice')"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('customer.terms-and-agreements')"
      toggleable
      :collapsed="panelCollapsedTermsAgreement"
      class="c-card-font mb-4"
      :pt:header:onClick="() => (panelCollapsedTermsAgreement = !panelCollapsedTermsAgreement)"
    >
      <div class="grid grid-cols-12 mb-4">
        <Card class="col-span-12 lg:col-span-7 shadow-none" pt:body:class="px-2 py-0">
          <template #content>
            <div class="col-span-6 lg:col-span-3">
              <div class="grid grid-cols-4">
                <div class="mb-2 mt-4">
                  <DefaultFreightMethod
                    v-model:defaultFreightMethod="customer.freight.defaultFreightMethodId"
                    :freightMethods="freightMethods"
                  />
                </div>
                <div class="mb-2 mt-4">
                  <DefaultPaymentTerm v-model:paymentTermId="customer.payment.defaultPaymentTermId" />
                </div>
                <div class="mb-2 mt-4">
                  <CreditLimit v-model:creditLimit="customer.payment.creditLimit" />
                </div>
                <div class="mb-2 mt-4 mr-8">
                  <Currency v-model:currency="customer.payment.currencyIso" />
                </div>
              </div>
            </div>
            <div class="col-span-6 lg:col-span-3">
              <div class="mb-2 mt-4">
                <FreightAgreements
                  v-model:freightAgreements="customer.freight.freightAgreements"
                  :freightMethods="freightMethods"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="col-span-12 lg:col-span-5 shadow-none lg:border-l rounded-none" pt:body:class="ml-2 p-0">
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-5 lg:border-r">
                <CustomerGroup v-model:customerGroups="customer.customerGroupIds" />
              </div>
              <div class="col-span-7">
                <Documents v-model:documents="customer.documents" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </Panel>
    <Panel
      :header="t('customer.contact-list.label')"
      toggleable
      :collapsed="panelCollapsedContactList"
      class="c-card-font mb-4"
      v-if="isBusinessCustomer"
      :pt:header:onClick="() => (panelCollapsedContactList = !panelCollapsedContactList)"
    >
      <div class="w-full">
        <ContactList v-model:contacts="customer.businessCustomer.contacts" />
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import OrganizationNumber from "../components/OrganizationNumber.vue";
import CustomerType from "../components/CustomerType.vue";
import Address from "../components/Address.vue";
import CreditLimit from "../components/CreditLimit.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import CustomerGroup from "../components/customer-group/CustomerGroup.vue";
import DefaultFreightMethod from "../components/DefaultFreightMethod.vue";
import FreightAgreements from "../components/FreightAgreements.vue";
import Currency from "../components/Currency.vue";
import CustomerEmail from "../components/CustomerEmail.vue";
import PhoneInput from "../components/PhoneInput.vue";
import ContactList from "../components/ContactList.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import CompanyName from "../components/CompanyName.vue";
import CustomerNumber from "../components/CustomerNumber.vue";
import CustomerState from "../components/CustomerState.vue";
import { customerAddressFunctions } from "../hooks/CustomerAddressFunction";

import Documents from "../components/Documents.vue";
import { NewCustomer } from "@/models/customer/NewCustomer";
import { CustomerType as CustomerTypeModel } from "@/models/customer/CustomerType";
import { Country } from "@/models/country/Country";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCustomerStore } from "@/stores/CustomerStore";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useAuth } from "@cumulus/event-bus";
import { useClient } from "@/api/client/ClientService";
import { UnsavedChangesDialog } from "@cumulus/components";

const { createCustomer } = useCustomerStore();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const val = useValidate();
const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const customer = ref(new NewCustomer());
const customersCreated = ref<NewCustomer[]>([]);
const customerId = ref("");
const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = customerAddressFunctions(customer);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialCustomer = ref<NewCustomer>();
const confirmedDiscard = ref(false);

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToCustomerList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  customerId.value = await createCustomer(customer.value);
  customersCreated.value.push(customer.value);
  customer.value = new NewCustomer();
  val.value.$reset();
  window.scrollTo(0, 0);
};

const isBusinessCustomer = computed<boolean>(() => {
  return customer.value.customerType === CustomerTypeModel.Business;
});

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

const panelCollapsedAdresses = ref(false);
const panelCollapsedTermsAgreement = ref(false);
const panelCollapsedContactList = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedAdresses.value && panelCollapsedTermsAgreement.value && panelCollapsedContactList.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAdresses.value = newState;
  panelCollapsedTermsAgreement.value = newState;
  panelCollapsedContactList.value = newState;
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);

  const client = await getClient((await getAuthHeaders()).clientId);
  customer.value.address.countryIso = client.countryIso;
  customer.value.payment.currencyIso = client.currencyIso;
  customer.value.documentLanguageIso = client.countryIso;

  Promise.all([fetchCountryData(), fetchFreightMethods()]).then(() => {
    initialCustomer.value = cloneDeep(customer.value);
  });
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCustomerList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const hasUpdates = computed(() => {
  const result = !isEqual(customer.value, initialCustomer.value);
  return result;
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCustomerList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

useShortcut(ShortcutAction.save, onCreate);

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});
</script>

<style scoped lang="scss">
:deep(.p-message.p-message-info) {
  background: var(--c-message-info-bg);
  color: var(--c-message-info-text);
}

.customer-created-link {
  color: var(--c-blue-50);
  text-decoration: underline;
}

.c-card-font {
  font-size: 12.8px;
}
</style>
