{
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists",
    "today-date": "{property} must be today's date or later",
    "to-time-must-be-later-than-from-time": "To time must be later than from time",
    "to-date-must-be-later-than-from-date": "To date must be later than from date",
    "summary": "Validation failed",
    "detail": "Correct all red fields and try again"
  },
  "error-detail": {
    "error-occured": "An error occured",
    "unknown-error": "Unknown error",
    "server-error": "Server error",
    "not-found": "Not found",
    "bad-request": "Bad request"
  },
  "common": {
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "add": "Add",
    "reload": "Reload",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "hide-filter": "Hide filter",
    "show-filter": "Show filter",
    "clear-filter": "Clear filter",
    "filter": "Filter",
    "from-date": "From date",
    "to-date": "To date",
    "status": "Status",
    "type": "Type",
    "general": "General",
    "open-panels": "Open panels",
    "close-panels": "Close panels",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "delete-confirm": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "sorting-by": "Sorting by"
  }
}
