<template>
  <Stepper value="1">
    <StepList>
      <Step value="1">{{ t("product.import.upload") }}</Step>
      <Step value="2">{{ t("product.import.map") }}</Step>
      <Step value="3">{{ t("product.import.review") }}</Step>
    </StepList>
    <StepPanels>
      <StepPanel v-slot="{ activateCallback }" value="1">
        <div class="max-w-6xl mx-auto p-6">
          <FileUpload @fileUploaded="onFileUploaded(activateCallback)" />
        </div>
        <div class="flex pt-6 justify-end">
          <PrimeButton
            :label="t('product.import.next')"
            icon="pi pi-arrow-right"
            iconPos="right"
            @click="activateCallback('2')"
          />
        </div>
      </StepPanel>
      <StepPanel v-slot="{ activateCallback }" value="2">
        <MapColumns v-model:columnMappings="columnMappings" :columns="columns" :rows="rows" />

        <div class="flex pt-6 justify-between">
          <PrimeButton
            :label="t('product.import.back')"
            severity="secondary"
            icon="pi pi-arrow-left"
            @click="activateCallback('1')"
          />
          <PrimeButton
            :label="t('product.import.next')"
            icon="pi pi-arrow-right"
            iconPos="right"
            @click="activateCallback('3')"
          />
        </div>
      </StepPanel>
      <StepPanel v-slot="{ activateCallback }" value="3">
        <div class="c-under-construction">Here comes validation Step</div>
        <div class="flex pt-6 justify-between">
          <PrimeButton
            :label="t('product.import.back')"
            severity="secondary"
            icon="pi pi-arrow-left"
            @click="activateCallback('2')"
          />
        </div>
      </StepPanel>
    </StepPanels>
  </Stepper>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useProductImportStore } from "../api/import/ProductImportStore";
import { storeToRefs } from "pinia";
import FileUpload from "../components/import/FileUpload.vue";
import MapColumns from "../components/import/MapColumns.vue";
import { useI18n } from "vue-i18n";

export interface ColumnMapping {
  importColumn: string;
  systemField: string;
}

const columnMappings = ref<ColumnMapping[]>([]);
const productImportStore = useProductImportStore();
const { columns, rows } = storeToRefs(productImportStore);
const { t } = useI18n();

const onFileUploaded = (goToStep: (value: string | number) => void) => {
  columnMappings.value = columns.value.map((col) => ({
    importColumn: col,
    systemField: "",
  }));

  goToStep("2");
};
</script>

<style scoped>
:deep(.p-button) {
  background-color: white;
  color: black;
  border: 1px solid #d1d5db;
  &:hover {
    background-color: #f9fafb;
  }
}

:deep(.p-dropdown) {
  border: 1px solid #d1d5db;
}

:deep(.p-datatable-scrollable) {
  overflow-x: auto;
}
</style>
