<template>
  <BeforeUnloadBrowserTab :hasUpdates="isOrderChanged" />
  <OrderHeader
    :editMode="true"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :showSidebar="showSidebar"
    :isOrderChanged="isOrderChanged"
    :isSaving="isSaving"
    :orderStatus="currentOrder.orderStatus"
    @toggleSidebarClicked="toggleSidebar"
    @onDelete="onDeleteOrder"
    @onCancel="orderRouteService.onCancel"
    @onCommit="onSave"
    @stayOnPage="orderRouteService.stayOnPage"
    @routeToOrderSearch="orderRouteService.routeToOrderSearch"
  />

  <div
    class="c-order"
    :class="[
      showSidebar && !useOverlaySidebar ? 'c-show-sidebar' : 'c-no-sidebar',
      { useOverlaySidebar: 'c-overlay-sidebar' },
    ]"
  >
    <div class="c-content">
      <div class="c-content-top">
        <div class="c-content-top-left mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-full -mt-2 mb-2">
                  <OrderInfo
                    :isNewOrder="false"
                    :orderStatus="currentOrder.orderStatus"
                    :orderNumber="currentOrder.orderNumber"
                    :registered="currentOrder.registered"
                    :seller="currentOrder.seller"
                  />
                </div>

                <div class="c-col-1 pr-6">
                  <div class="mt-4 mb-6 -mx-6">
                    <CustomerInput
                      v-model:focusSearchInput="focusCustomerSearchInput"
                      :customer="currentOrder.customer"
                      :orderLines="currentOrder.orderLines"
                      :orderStatus="currentOrder.orderStatus"
                      @selectedCustomerId="editOrderStore.setActiveCustomerById"
                    />
                  </div>

                  <div class="mb-6 -mx-6">
                    <ContactInput
                      :contact="currentOrder.customer.contact"
                      :customerContacts="customerContacts"
                      :orderStatus="currentOrder.orderStatus"
                      @selectedContact="selectedContact"
                    />
                  </div>

                  <div class="my-4 -mx-6">
                    <ContactEmailInput
                      v-model:email="currentOrder.customer.contact.email"
                      :orderStatus="currentOrder.orderStatus"
                    />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="-mt-1.5">
                    <InformationTabs
                      v-model:delivery="currentOrder.delivery"
                      :customer="currentOrder.customer"
                      :allowEdit="allowEditOrder"
                      :invoiceAddress="currentOrder.invoiceAddress"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 -mx-4">
                  <div class="mt-4 mb-6">
                    <OrderReference
                      v-model:orderReference="currentOrder.orderReference"
                      :allowEditOrder="allowEditOrder"
                    />
                  </div>

                  <div class="mb-6">
                    <PaymentTerms
                      v-model:paymentTerm="currentOrder.paymentTerm"
                      :paymentTermIdFromCustomer="activeCustomer?.payment.defaultPaymentTermId ?? ''"
                      :orderStatus="currentOrder.orderStatus"
                    />
                  </div>

                  <div class="mb-6">
                    <FreightMethod
                      v-model:freightMethod="currentOrder.freightMethod"
                      :defaultFreightMethodId="activeCustomer?.freight.defaultFreightMethodId ?? ''"
                      :orderStatus="currentOrder.orderStatus"
                      @reCalculateOrderTotalPrice="editOrderStore.reCalculateOrderTotalPrice"
                    />
                  </div>
                </div>

                <div class="c-col-2 -mx-4">
                  <div class="mt-4 mb-6">
                    <ShippingPrice
                      v-model:shippingPrice="currentOrder.shippingPrice"
                      v-model:agreedFreight="currentOrder.agreedFreight"
                      :orderStatus="currentOrder.orderStatus"
                      :freightMethod="currentOrder.freightMethod"
                      currencyIso="NOK"
                      @shippingCalculationDialogVisible="shippingCalculationDialogVisible = $event"
                    />
                  </div>

                  <div class="mb-6">
                    <CommentInput v-model:comment="currentOrder.comment" :orderStatus="currentOrder.orderStatus" />
                  </div>

                  <div class="mt-4 mb-6 -mr-4">
                    <DeliveryOption
                      v-model:deliveryOption="currentOrder.deliveryOption"
                      :allowEditOrder="allowEditOrder"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <div class="c-order-lines-content">
        <Card class="c-font-size c-order-lines-card m-2" pt:body:class="pt-2">
          <template #content>
            <div class="c-order-tables">
              <Suspense>
                <OrderLines
                  v-if="!advancedProductSearch"
                  v-model:orderLines="currentOrder.orderLines"
                  v-model:advancedProductSearch="advancedProductSearch"
                  v-model:selectedProductId="selectedProductId"
                  v-model:focusFirstOrderLine="focusFirstOrderLine"
                  :allowAddProducts="allowAddProducts"
                  :allowEditOrder="allowEditOrder"
                  :orderStatus="currentOrder.orderStatus"
                />
              </Suspense>
              <ProductSearch
                v-model:advancedProductSearch="advancedProductSearch"
                v-model:selectedProductId="selectedProductId"
                v-model:focusFirstOrderLine="focusFirstOrderLine"
                :allowAddProducts="allowAddProducts"
                :orderLines="currentOrder.orderLines"
                :customerId="currentOrder.customer?.id"
                :defaultWarehouseId="defaultWarehouseId"
                :allowEditOrder="allowEditOrder"
                @createNewOrderLine="editOrderStore.addOrderLine($event.orderLine, $event.warehouseId)"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>

    <Card v-if="!useOverlaySidebar" class="c-right-bar c-font-size m-2">
      <template #content>
        <OrderInfoSidebar
          v-model:currentOrder="currentOrder"
          :allowEditOrder="allowEditOrder"
          :selectedProductId="selectedProductId"
        />
      </template>
    </Card>

    <Drawer v-else v-model:visible="showSidebar" position="right" class="md:w-80 lg:w-[30rem]">
      <OrderInfoSidebar
        v-model:currentOrder="currentOrder"
        :allowEditOrder="allowEditOrder"
        :selectedProductId="selectedProductId"
      />
    </Drawer>
  </div>

  <OrderFooter
    v-model:shippingPrice="currentOrder.shippingPrice"
    v-model:agreedFreight="currentOrder.agreedFreight"
    :calculatedOrderTotal="calculatedOrderTotal"
    :orderStatus="currentOrder.orderStatus"
    :orderlines="currentOrder.orderLines.length"
    :class="[
      showSidebar && !useOverlaySidebar ? 'c-show-sidebar' : 'c-no-sidebar',
      useOverlaySidebar ? 'c-overlay-sidebar' : '',
    ]"
    @reCalculateOrderTotal="editOrderStore.reCalculateOrderTotalPrice"
  />

  <PrimeDialog
    v-model:visible="shippingCalculationDialogVisible"
    :modal="true"
    :header="t('common.shipping-dialog-header')"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="c-checkout-dialog w-3/12"
    data-testid="calculate-shipping-modal"
    @show="calculateParcels"
  >
    <Parcels v-model:parcels="parcels" @recalculate-bring="onRecalculateBring" />
    <div class="col-span-12 md:col-span-12 lg:col-span-12">
      <BringError :errors="shippingGuideErrors" />
    </div>
    <FreightSummary :parcels="parcels" :weight="weight" :cost="bringPriceIncVat" />

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            tabindex="47"
            class="c-dialog-default-button"
            @click="shippingCalculationDialogVisible = false"
          />

          <PrimeButton
            :label="t('common.confirm')"
            data-testid="btn-confirm-checkout"
            class="c-dialog-default-button c-dialog-success-button"
            tabindex="46"
            @click="onConfirmBringShippingHeader"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
//Components
import OrderLines from "../components/OrderLines.vue";
import OrderReference from "../components/OrderReference.vue";
import PaymentTerms from "../components/PaymentTerms.vue";
import DeliveryOption from "../components/DeliveryOption.vue";
import FreightMethod from "../components/FreightMethod.vue";
import OrderHeader from "../components/OrderHeader.vue";
import OrderInfoSidebar from "@/components/sidebar/OrderInfoSidebar.vue";
import ProductSearch from "../components/ProductSearch.vue";
import OrderFooter from "../components/OrderFooter.vue";
import BringError from "@/components/BringError.vue";
import Parcels from "@/components/Parcels.vue";
import FreightSummary from "@/components/FreightSummary.vue";
import CustomerInput from "@/components/customer/CustomerInput.vue";
import InformationTabs from "@/components/info-tabs/InformationTabs.vue";
import ContactInput from "@/components/contact/ContactInput.vue";
import ContactEmailInput from "@/components/contact/ContactEmailInput.vue";
import CommentInput from "@/components/comment/CommentInput.vue";
import ShippingPrice from "@/components/shipping-price/ShippingPriceInput.vue";
import OrderInfo from "@/components/OrderInfo.vue";

import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useEditOrderStore } from "@/stores/EditOrderStore";
import { useOrderRouteService } from "@/api/order/OrderRouteService";
import { useOrderSidebar } from "@/components/sidebar/OrderSidebar";
import { useBringStore } from "@/stores/BringStore";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { Parcel } from "@/models/bring/Parcel";
import { OrderLine } from "@/models/order/OrderLine";
import { OrderContact } from "@/models/order/OrderContact";

const onConfirmBringShippingHeader = () => {
  currentOrder.value.shippingPrice = bringPriceIncVat.value;
  shippingCalculationDialogVisible.value = false;
  editOrderStore.reCalculateOrderTotalPrice();
};

const calculateParcels = () => {
  parcels.value = [new Parcel(1, calculateWeight(currentOrder.value.orderLines))];
  onRecalculateBring();
};

const calculateWeight = (orderLines: OrderLine[]) => {
  return orderLines.reduce((acc, orderLine) => acc + orderLine.product.weight.value * orderLine.quantity, 0);
};

const { t } = useI18n();
const route = useRoute();

const shippingCalculationDialogVisible = ref(false);
const editOrderStore = useEditOrderStore();
const {
  currentOrder,
  isOrderChanged,
  calculatedOrderTotal,
  advancedProductSearch,
  allowEditOrder,
  customerContacts,
  activeCustomer,
  selectedProductId,
  isSaving,
  focusFirstOrderLine,
  allowAddProducts,
} = storeToRefs(editOrderStore);

const defaultWarehouseId = ref<string>("");
onMounted(async () => {
  defaultWarehouseId.value = await editOrderStore.getDefaultWarehouseId();
});

const orderId = computed(() => currentOrder.value.id);
const orderRouteService = useOrderRouteService(isOrderChanged, orderId);
const { unsavedChangesDialogVisible } = orderRouteService;

const { showSidebar, useOverlaySidebar, toggleSidebar } = useOrderSidebar();

const { getAllWarehouses } = useWarehouse();
const warehouse = ref<Warehouse>();

const bringStore = useBringStore();
const { weight, parcels, bringPriceIncVat, shippingGuideErrors } = storeToRefs(bringStore);

// Nested validation
const val = useValidate();
const { addToast, SeverityType } = useErrorHandler();

const focusCustomerSearchInput = ref(false);

const onSave = async () => {
  unsavedChangesDialogVisible.value = false;
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }

  val.value.$touch();
  if (!(await val.value.$validate())) {
    addToast(SeverityType.Warning, t("order.add.toast.validation.summary"), t("order.add.toast.validation.detail"));
    return;
  }

  if ((await editOrderStore.updateOrder()) === true) {
    orderRouteService.routeToOrderSearch();
  }
};

onMounted(async () => {
  const warehouses = await getAllWarehouses();
  if (warehouses.length > 0 && warehouses[0] != null) {
    warehouse.value = warehouses[0];
  }
  if ((await editOrderStore.getOrder(route.params.id as string)) === null) {
    orderRouteService.routeToOrderSearch();
  }
});

const onDeleteOrder = async () => {
  if (await editOrderStore.deleteOrder(currentOrder.value.id)) {
    addToast(
      SeverityType.Success,
      t("order.delete.toast.success.summary"),
      currentOrder.value.customer.contact.firstName + " " + currentOrder.value.customer.contact.lastName,
    );
    orderRouteService.routeToOrderSearch();
  }
};

const onRecalculateBring = () => {
  if (currentOrder.value.freightMethod.bring == null) return;
  if (weight.value === 0) return;
  if (warehouse.value == null) return;

  try {
    bringStore.getBringShippingGuide(currentOrder.value, warehouse.value);
  } catch {
    addToast(SeverityType.Warning, t("checkout.error"), t("checkout.error-getting-shipping-guide"));
  }
};

useShortcut(ShortcutAction.save, onSave);

const selectedContact = (contact: OrderContact) => {
  currentOrder.value.customer.contact = contact;
};
</script>

<style lang="scss" scoped>
.c-order {
  margin: 0.625rem 0.6rem 1rem 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-full {
  flex: 100%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-invoice-address {
  @media (min-width: 992px) {
    min-height: 7rem;
    background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 6px;
    background-repeat: repeat-y;
  }
}

.c-order-lines-card {
  min-height: calc(100vh - 35rem);
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
  width: calc(100% - 26rem);
}

.c-right-bar {
  position: fixed;
  top: 7rem;
  bottom: 2rem;
  right: 1rem;
  width: 25rem;
  transition: all 0.25s;
}

.c-order.c-no-sidebar {
  .c-content {
    width: 100%;
    transition: all 0.25s;
  }
}

.c-no-sidebar .c-right-bar {
  margin-right: -28rem;
}

.is-mobile .c-content .c-font-size {
  margin: 0.6rem 0;
}

.c-tool-material .material-symbols-outlined {
  padding: 2px;
  font-weight: 500;
}

.c-freight-button {
  height: 32px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: black !important;
  background-color: var(--p-card-background) !important;
  border: none;
}
.c-transport-icon {
  background: var(--p-primary-color);
  color: white;
}
</style>
