<template>
  <div class="ml-8">
    <FloatLabel variant="on">
      <Select
        id="product-account-group"
        overlayClass="hidden"
        optionLabel="name"
        data-testid="product-account-group"
        v-model="selectedAccountGroup"
        :options="accountGroups"
        class="w-full"
        pt:list:data-testid="product-account-group-list"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('product.account-group.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        @click="showAccountGroupPanel"
      />

      <label for="product-account-group">
        {{ t(`product.account-group.label`) }}
      </label>
    </FloatLabel>
    <AccountGroupPanel
      ref="accountGroupPanelRef"
      :accountGroups="accountGroups"
      @accountGroupCreated="onAccountGroupCreated"
      @accountGroupSelected="selectedAccountGroup = $event"
    />
  </div>
</template>

<script setup lang="ts">
import { AccountGroup } from "@/product/models/AccountGroup";
import AccountGroupPanel from "./AccountGroupPanel.vue";
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useAccountGroupStore } from "@/stores/AccountGroupStore";

const { t } = useI18n();

const props = defineProps<{
  accountGroupId: string;
}>();

const emit = defineEmits<{
  (e: "update:accountGroupId", type: string): void;
}>();

const { accountGroups } = storeToRefs(useAccountGroupStore());

const onAccountGroupCreated = (accountGroup: AccountGroup) => {
  selectedAccountGroup.value = accountGroup;
};

const selectedAccountGroup = computed<AccountGroup>({
  get: () => {
    return accountGroups.value.find((accountGroup) => accountGroup.id === props.accountGroupId) ?? new AccountGroup();
  },
  set: (value: AccountGroup) => {
    if (value?.id) {
      emit("update:accountGroupId", value.id);
    }
  },
});

const accountGroupPanelRef = ref();
const showAccountGroupPanel = (event: Event) => {
  accountGroupPanelRef.value.toggle(event);
};

watch(
  () => accountGroups.value,
  () => {
    if (!accountGroups.value.find((accountGroup) => accountGroup.id === props.accountGroupId)) {
      selectedAccountGroup.value =
        accountGroups.value.find((accountGroup) => accountGroup.name === "Standard") ?? new AccountGroup();
    }
  },
);
</script>
