{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "add": "Add new",
    "cancel": "Cancel",
    "save": "Save changes",
    "delete": "Delete",
    "search": "Search freight",
    "error": "An error occured",
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Not found"
    },
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have some unsaved changes. You can save your changes, discard your changes, or cancel to continue editing",
    "ignore": "Ignore"
  },
  "freight": {
    "add-bring-service-agreement-header": "Add freight agreement",
    "update-bring-service-agreement-header": "Update freight agreement",
    "delete-bring-service-agreement-confirm-message": "Are you sure you want to delete this agreement?",
    "service-agreement-added": "Agreement added",
    "service-agreement-deleted": "Agreement deleted",
    "service-agreement-updated": "Agreement updated",
    "header-add": "Add freight method",
    "header-edit": "Change freight method",
    "name": "Name",
    "customer-number": "Customer number",
    "client-url": "Client URL",
    "carrier": "Carrier",
    "service-agreement": "Agreement",
    "short-name": "Short name",
    "methods": "Freight methods",
    "empty-list": "No freight methods found.",
    "loading-list": "Loading freight methods, please wait...",
    "load-failed": "Failed to load freight methods",
    "in-use": "Freight method is available, set it to inactive to delete it",
    "delete-confirm-message": "Are you sure you want to delete this freight method?",
    "tax": "Tax",
    "default": "default",
    "available": {
      "label": "Visible for:",
      "customers-label": "Customers",
      "suppliers-label": "Suppliers",
      "webshop-label": "Webshop"
    },
    "toast": {
      "add-success": {
        "summary": "Freight method is added!",
        "detail": "Freight method: {name}"
      },
      "update-success": {
        "summary": "Freight method is updated!",
        "detail": "Freight method: {name}"
      },
      "delete-success": {
        "summary": "Freight method is deleted!",
        "detail": "Freight method: {name}"
      }
    },
    "pick-service-agreement-placeholder": "Choose freight agreement",
    "pick-service-placeholder": "Choose a service",
    "my-bring-api-key": "MyBring Api Key",
    "my-bring-api-uid": "MyBring Api Uid"
  }
}
