<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('packing-note.header')" :itemsList="items"></ColumnOptionsMenu>

    <ContextMenu ref="cm" :model="menuModel" data-testid="packing-note-context-menu" />
    <DataTable
      :value="filteredPackingNotes"
      dataKey="id"
      :autoLayout="true"
      responsiveLayout="stack"
      breakpoint="999px"
      selectionMode="single"
      @row-dblclick="onRowDblClick"
      class="c-packing-note-table c-datatable"
      :loading="loading"
      :paginator="true"
      :rows="25"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      stripedRows
      contextMenu
      v-model:contextMenuSelection="selectedPackingNote"
      @rowContextmenu="onRowContextMenu"
      data-testid="packing-note-table"
      :sortField="sortField"
      :sortOrder="sortOrder"
      @sort="onSort"
      @page="onPage"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ field, data, index }">
          <template v-if="col.field === PackingNoteListColumn.CreatedDateUtc">
            {{ d(data.createdDateUtc, "short") }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.PackingNoteNumber">
            {{ data.packingNoteNumber }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.OrderNumber">
            {{ data.orderNumber }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.FreightMethodName">
            {{ data.freightMethodName }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.CustomerNumber">
            {{ data.customerNumber }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.InvoiceName">
            {{ data.invoiceName }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.NumberOfLines">
            {{ data.numberOfLines }}
          </template>
          <template v-else-if="col.field === PackingNoteListColumn.Pdf">
            <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
          </template>

          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("packing-note.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />

  <PrintDialog v-model:visibleDialog="showPrintModal" :packingNote="selectedPackingNote" v-if="showPrintModal" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, nextTick, onMounted, ref } from "vue";
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import PrintDialog from "../components/PackingPrintDialog.vue";
import { useDocumentService } from "../../document/DocumentService";
import { usePackingNoteStore } from "../api/PackingNoteStore";
import { PackingNote } from "../models/PackingNote";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";
import { PackingNoteListColumn } from "../models/PackingNoteListColumn";

const { getUser } = useAuth();
const { t, d } = useI18n();
const toast = useCumulusToast(useToast());
const packingNoteStore = usePackingNoteStore();
const { getPackingNotes } = packingNoteStore;
const { packingNotes, loading } = storeToRefs(packingNoteStore);
const { getPackingNoteUrl } = useDocumentService();
const query = ref("");
const showPrintModal = ref(false);
const selectedPackingNote = ref<PackingNote>();
const cm = ref();
const sortField = ref("");
const sortOrder = ref(-1);
const visible = ref(false);

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
}>();

const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const packingNoteSearchColumns: DataTableColumn[] = [
  { field: "createdDateUtc", header: t("packing-note.created-date"), sortable: true },
  {
    field: "packingNoteNumber",
    header: t("packing-note.packing-note-number"),
    class: "text-right",
    sortable: true,
  },
  { field: "orderNumber", header: t("packing-note.order-number"), class: "text-right", sortable: true },
  { field: "freightMethodName", header: t("packing-note.freight-method-name"), class: "text-right", sortable: true },
  { field: "customerNumber", header: t("packing-note.customer-number"), class: "text-right", sortable: true },
  { field: "invoiceName", header: t("packing-note.customer-name"), class: "text-right", sortable: true },
  {
    field: "numberOfLines",
    header: t("packing-note.quantity-of-order-items"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "pdf",
    header: "PDF",
    class: "c-pdf-icon",
    sortable: false,
  },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("packingNoteSearch", packingNoteSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== PackingNoteListColumn.PackingNoteNumber &&
      col.field !== PackingNoteListColumn.OrderNumber &&
      col.field !== PackingNoteListColumn.InvoiceName,
  );
});

const filteredPackingNotes = computed<PackingNote[]>(() => {
  if (packingNotes.value.length === 0) {
    return [];
  }
  return packingNotes.value.filter((packing: PackingNote) => {
    return (
      packing.orderNumber.toString().startsWith(query.value.trim()) ||
      packing.packingNoteNumber.toString().startsWith(query.value.trim())
    );
  });
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickPackingNoteRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const onClickPackingNoteRefresh = async () => {
  loading.value = true;
  await getPackingNotes();
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? packingNoteSearchColumns
    : packingNoteSearchColumns.filter(
        (c) =>
          c.field === PackingNoteListColumn.PackingNoteNumber ||
          c.field === PackingNoteListColumn.OrderNumber ||
          c.field === PackingNoteListColumn.InvoiceName,
      );
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  downloadPdf((event.data as PackingNote).id);
};

const downloadPdf = async (id: string) => {
  try {
    loading.value = true;
    const url = await getPackingNoteUrl(id);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("packing-note.download-link-missing"),
      });
    }
  } finally {
    loading.value = false;
  }
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }

    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

onMounted(async () => {
  await getPackingNotes();
});
</script>

<style scoped lang="scss">
.c-packing-note-table {
  :deep(.p-datatable-thead > tr > .text-right > .p-column-header-content > .p-column-title) {
    width: 100%;
    text-align: right;
  }
}
.c-packing-note {
  margin: var(--default-content-margin);
}
.c-packing-note .card {
  overflow: auto;
}

:deep(.c-datatable.p-datatable .p-datatable-tbody > tr > td.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

:deep(.c-datatable.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

.c-header-icon-container {
  background: transparent;
}
</style>
