import { httpClient, AuthHeaders } from "@cumulus/http";
import { Warehouse } from "./model/Warehouse";

class WarehouseApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? import.meta.env.VITE_APP_API_WAREHOUSE + "/warehouses"
        : `${import.meta.env.VITE_APP_API_URL as string}/warehouses`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Warehouse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Warehouse> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, warehouse: Warehouse): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", warehouse);
  }

  public async update(authHeaders: AuthHeaders, warehouse: Warehouse): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", warehouse);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}
const warehouseApi = new WarehouseApi();
export { warehouseApi };
