import { CurrencyInfo } from "@/models/currency/CurrencyInfo";
import { httpClient, AuthHeaders } from "@cumulus/http";
import { CurrencyExchangeRate } from "@/models/currency/CurrencyExchangeRate";
import { Currency } from "@/models/currency/Currency";

class CurrencyApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/currencies`
        : `${import.meta.env.VITE_APP_API_URL as string}/currencies`;
  }
  public async getAll(authHeaders: AuthHeaders): Promise<CurrencyInfo[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async updateExchangeRate(
    authHeaders: AuthHeaders,
    currencyExchangeRates: CurrencyExchangeRate[]
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/exchange-rates", { exchangeRates: currencyExchangeRates });
  }

  public async create(authHeaders: AuthHeaders, currency: Currency): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", currency);
  }

  public async update(authHeaders: AuthHeaders, currency: Currency): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", currency);
  }
}
export const currencyApi = new CurrencyApi();
