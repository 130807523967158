import { Ref, computed } from "vue";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";
import { GroupLevelInfo } from "@/product-hierarchy/model/GroupLevelInfo";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import { ProductGroupNameByLanguage } from "@/product-hierarchy/model/ProductGroupNameByLanguage";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { NIL as emptyUuid } from "uuid";

export function useProductGroupFunctions(
  productHierarchies: Ref<ProductHierarchy[] | undefined>,
  groupLevelInfo: Ref<GroupLevelInfo>,
) {
  const mapGroupNames = (groups: ProductGroupName[], languageIso: LanguageIsoType): ProductGroupNameByLanguage[] => {
    return groups
      .map((group) => ProductGroupNameByLanguage.createFromProductGroupName(group, languageIso))
      .filter((group) => group !== null) as ProductGroupNameByLanguage[];
  };

  const getUniqueGroupNames = (groups: ProductGroupName[]): ProductGroupName[] => {
    const uniqueGroups = new Map<string, ProductGroupName>();
    groups.forEach((group) => {
      if (!uniqueGroups.has(group.id)) {
        uniqueGroups.set(group.id, group);
      }
    });
    return Array.from(uniqueGroups.values());
  };

  const getLevelGroups = (level: number, parentId?: string): ProductGroupName[] => {
    const levelsSelected = [
      groupLevelInfo.value.groupLevel1.id,
      groupLevelInfo.value.groupLevel2.id,
      groupLevelInfo.value.groupLevel3.id,
      groupLevelInfo.value.groupLevel4.id,
      groupLevelInfo.value.groupLevel5.id,
    ];
    const levelsAboveSelected = levelsSelected.slice(0, level - 1).some((id) => id !== emptyUuid);

    return getUniqueGroupNames(
      (productHierarchies.value ?? [])
        .flatMap((hierarchy) => {
          switch (level) {
            case 1:
              return hierarchy.level1 ? [hierarchy.level1] : [];
            case 2:
              return levelsAboveSelected && parentId === emptyUuid
                ? []
                : parentId === emptyUuid || (hierarchy.level1 && hierarchy.level1.id === parentId)
                  ? hierarchy.level2
                    ? [hierarchy.level2]
                    : []
                  : [];
            case 3:
              return levelsAboveSelected && parentId === emptyUuid
                ? []
                : parentId === emptyUuid || (hierarchy.level2 && hierarchy.level2.id === parentId)
                  ? hierarchy.level3
                    ? [hierarchy.level3]
                    : []
                  : [];
            case 4:
              return levelsAboveSelected && parentId === emptyUuid
                ? []
                : parentId === emptyUuid || (hierarchy.level3 && hierarchy.level3.id === parentId)
                  ? hierarchy.level4
                    ? [hierarchy.level4]
                    : []
                  : [];
            case 5:
              return levelsAboveSelected && parentId === emptyUuid
                ? []
                : parentId === emptyUuid || (hierarchy.level4 && hierarchy.level4.id === parentId)
                  ? hierarchy.level5
                    ? [hierarchy.level5]
                    : []
                  : [];
            default:
              return [];
          }
        })
        .filter((group) => group !== null),
    );
  };

  const level1Groups = computed(() => getLevelGroups(1));
  const level2Groups = computed(() => getLevelGroups(2, groupLevelInfo.value.groupLevel1.id || emptyUuid));
  const level3Groups = computed(() => getLevelGroups(3, groupLevelInfo.value.groupLevel2.id || emptyUuid));
  const level4Groups = computed(() => getLevelGroups(4, groupLevelInfo.value.groupLevel3.id || emptyUuid));
  const level5Groups = computed(() => getLevelGroups(5, groupLevelInfo.value.groupLevel4.id || emptyUuid));

  const numberOfLevelsUsed = computed<number>(() => {
    return (productHierarchies.value ?? []).reduce((max, hierarchy) => {
      let levels = 1;
      if (hierarchy.level2 && hierarchy.level2.id) levels = 2;
      if (hierarchy.level3 && hierarchy.level3.id) levels = 3;
      if (hierarchy.level4 && hierarchy.level4.id) levels = 4;
      if (hierarchy.level5 && hierarchy.level5.id) levels = 5;
      return Math.max(max, levels);
    }, 1);
  });

  const resetLevelsBelow = (level: number) => {
    if (level <= 1) groupLevelInfo.value.groupLevel2 = new ProductGroupNameByLanguage();
    if (level <= 2) groupLevelInfo.value.groupLevel3 = new ProductGroupNameByLanguage();
    if (level <= 3) groupLevelInfo.value.groupLevel4 = new ProductGroupNameByLanguage();
    if (level <= 4) groupLevelInfo.value.groupLevel5 = new ProductGroupNameByLanguage();
  };

  const setGroupForLevel = (level: number, group: ProductGroupNameByLanguage) => {
    switch (level) {
      case 1:
        groupLevelInfo.value.groupLevel1 = group;
        resetLevelsBelow(1);
        break;
      case 2:
        groupLevelInfo.value.groupLevel2 = group;
        resetLevelsBelow(2);
        break;
      case 3:
        groupLevelInfo.value.groupLevel3 = group;
        resetLevelsBelow(3);
        break;
      case 4:
        groupLevelInfo.value.groupLevel4 = group;
        resetLevelsBelow(4);
        break;
      case 5:
        groupLevelInfo.value.groupLevel5 = group;
        resetLevelsBelow(5);
        break;
    }
  };

  const getGroupLevelIdForSelectedLevel = (level: number): string => {
    switch (level) {
      case 1:
        return groupLevelInfo.value.groupLevel1.id;
      case 2:
        return groupLevelInfo.value.groupLevel2.id;
      case 3:
        return groupLevelInfo.value.groupLevel3.id;
      case 4:
        return groupLevelInfo.value.groupLevel4.id;
      case 5:
        return groupLevelInfo.value.groupLevel5.id;
      default:
        return "";
    }
  };

  const getOptionsForLevel = (level: number, languageIso: LanguageIsoType) => {
    switch (level) {
      case 1:
        return mapGroupNames(level1Groups.value, languageIso);
      case 2:
        return mapGroupNames(level2Groups.value, languageIso);
      case 3:
        return mapGroupNames(level3Groups.value, languageIso);
      case 4:
        return mapGroupNames(level4Groups.value, languageIso);
      case 5:
        return mapGroupNames(level5Groups.value, languageIso);
      default:
        return [];
    }
  };

  return {
    numberOfLevelsUsed,
    resetLevelsBelow,
    setGroupForLevel,
    getGroupLevelIdForSelectedLevel,
    getOptionsForLevel,
  };
}
