<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #content>
            <Suspense>
              <CustomerList
                :customers="customers"
                :loading="loading"
                :totalHits="totalHits"
                :page="page"
                :pageSize="pageSize"
                :sortField="sortField"
                :sortOrder="sortOrder"
                :visible="showSidebar"
                v-model:filters="filtersComputed"
                @update:sortField="onUpdateSortField"
                @update:sortOrder="onUpdateSortOrder"
                @update:page="onUpdatePage"
                @update:pageSize="onUpdatePageSize"
                @refreshList="onRefreshList"
                @onToggleFilterSidebar="onToggleFilterSidebar"
              />
            </Suspense>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('customer.search.filter')" position="right">
          <CustomerFilterSidebar
            v-model:filters="filtersComputed"
            :loading="loading"
            :customerGroups="customerGroupStore.customerGroups"
          />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="filter-sidebar ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("customer.search.filter") }}</span>
            </div>

            <CustomerFilterSidebar
              v-model:filters="filtersComputed"
              :loading="loading"
              :customerGroups="customerGroupStore.customerGroups"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";
import { SearchFilters } from "@/models/search/customer/SearchFilters";
import { ListCustomer } from "@/models/search/customer/ListCustomer";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import CustomerFilterSidebar from "../components/search/CustomerFilterSidebar.vue";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";
import CustomerList from "@/components/CustomerList.vue";
import { useSearch } from "@/api/search/SearchService";
import { useMobile } from "@/utils/MobileService";
import { useAuth } from "@cumulus/event-bus";

const { t } = useI18n();
const { getUser } = useAuth();

const customerGroupStore = useCustomerGroupStore();
const { customerSearch } = useSearch();
const route = useRoute();

const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const query = ref("");
const loading = ref(false);
const customers = ref<ListCustomer[]>([]);
const filters = ref<SearchFilters>(new SearchFilters());
const showSidebar = ref(false);
const sortOrder = ref(-1);
const sortField = ref("");

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const filtersComputed = computed<SearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: SearchFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await search();
};

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  }
);

let identityId = "";

onMounted(async () => {
  identityId = (await getUser()).getEmployee()?.identityId ?? "";
  const active = localStorage.getItem(`user-filter-customersearch-${identityId}`);
  if (active !== null) {
    showSidebar.value = JSON.parse(active);
  }

  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await customerGroupStore.fetchCustomerGroups();
  await search();
});

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
  localStorage.setItem(`user-filter-customersearch-${identityId}`, JSON.stringify(showSidebar.value));
};

const search = async () => {
  try {
    const request = new CustomerSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    request.filters = filters.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    const response = await customerSearch(request);
    if (response.customers.length === 0) {
      customers.value = [];
      totalHits.value = 0;
      return;
    }

    customers.value = response.customers.map((c: SearchCustomer) => {
      return new ListCustomer(c, customerGroupStore.customerGroups);
    });
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};
</script>

<style scoped lang="scss">
.c-customer-search {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>
