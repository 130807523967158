import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/theme/default";

import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";
import { createApp } from "vue";

import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Panel from "primevue/panel";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import InputMask from "primevue/inputmask";
import PanelMenu from "primevue/panelmenu";
import Select from "primevue/select";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import Editor from "primevue/editor";
import FileUpload from "primevue/fileupload";
import Popover from "primevue/popover";
import ContextMenu from "primevue/contextmenu";
import AutoComplete from "primevue/autocomplete";
import Card from "primevue/card";
import Badge from "primevue/badge";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import BadgeDirective from "primevue/badgedirective";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import Chip from "primevue/chip";
import FloatLabel from "primevue/floatlabel";
import Tag from "primevue/tag";
import {
  FloatLabelInput,
  FloatLabelDropdown,
  FloatLabelDropdownPanel,
  FloatLabelInputNumber,
  FloatLabelTextArea,
} from "@cumulus/components";
import Galleria from "primevue/galleria";
import Textarea from "primevue/textarea";
import Carousel from "primevue/carousel";
import ColorPicker from "primevue/colorpicker";
import Stepper from "primevue/stepper";
import StepList from "primevue/steplist";
import StepPanels from "primevue/steppanels";
import StepItem from "primevue/stepitem";
import Step from "primevue/step";
import StepPanel from "primevue/steppanel";

import vueDebounce from "vue-debounce";

const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("PanelMenu", PanelMenu);
  app.component("PrimeButton", Button);
  app.component("Checkbox", Checkbox);
  app.component("InputText", InputText);
  app.component("PrimeMenu", Menu);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("Panel", Panel);
  app.component("InputNumber", InputNumber);
  app.component("PrimeDialog", Dialog);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("InputMask", InputMask);
  app.component("Select", Select);
  app.component("Toast", Toast);
  app.component("MultiSelect", MultiSelect);
  app.component("ContextMenu", ContextMenu);
  app.component("Editor", Editor);
  app.component("FileUpload", FileUpload);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("SplitButton", SplitButton);
  app.component("Popover", Popover);
  app.component("AutoComplete", AutoComplete);
  app.component("Badge", Badge);
  app.component("InputGroup", InputGroup);
  app.component("InputGroupAddon", InputGroupAddon);
  app.component("Card", Card);
  app.component("TabList", TabList);
  app.component("Tab", Tab);
  app.component("Tabs", Tabs);
  app.component("TabPanels", TabPanels);
  app.component("TabPanel", TabPanel);
  app.component("Galleria", Galleria);
  app.component("PrimeTextarea", Textarea);
  app.component("Carousel", Carousel);
  app.component("ColorPicker", ColorPicker);
  app.component("Chip", Chip);
  app.component("FloatLabel", FloatLabel);
  app.component("Tag", Tag);
  app.component("Stepper", Stepper);
  app.component("StepList", StepList);
  app.component("StepPanels", StepPanels);
  app.component("StepItem", StepItem);
  app.component("Step", Step);
  app.component("StepPanel", StepPanel);

  app.component("FloatLabelInput", FloatLabelInput);
  app.component("FloatLabelDropdown", FloatLabelDropdown);
  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);
  app.component("FloatLabelInputNumber", FloatLabelInputNumber);
  app.component("FloatLabelTextArea", FloatLabelTextArea);

  app.directive("badge", BadgeDirective);
  app.directive("tooltip", Tooltip);

  app.directive("debounce", vueDebounce({ lock: true }));
}
