<template>
  <InventoryProductsHeader
    @openDetailedOverview="openInventoryProductOverview"
    @editInventoryProductQuantity="openEditInventoryQuantity"
    @editInventoryProductCost="openEditInventoryCost"
    @onToggleFilterSidebar="onToggleFilterSidebar"
    @inventoryChangesLogBtnClicked="openInventoryChangesLog"
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
    :manufacturers="manufacturers"
    :warehouses="warehouses"
  />

  <div class="c-inventory-list">
    <div class="flex">
      <div class="flex-auto">
        <Card>
          <template #content>
            <Suspense>
              <InventoryProductList
                ref="inventoryProductList"
                :products="products"
                v-model:selectedInventoryProduct="selectedInventoryProduct"
                :selectedWarehouseId="selectedWarehouseIdComputed"
                :totalHits="totalHits"
                :pageSize="pageSize"
                :page="page"
                :sortOrder="sortOrder"
                :sortField="sortField"
                :loading="loading"
                @update:sortOrder="onUpdateSortInventoryOrder"
                @rowSelected="onRowSelected"
                @update:sortField="onUpdateSortField"
                @rowDoubleClicked="onRowDblClick"
                @openInventoryProductOverview="openInventoryProductOverview"
                @openEditInventoryQuantity="openEditInventoryQuantity"
                @openEditInventoryCost="openEditInventoryCost"
                @update:page="onUpdatePage"
                @update:pageSize="onUpdatePageSize"
                @inventoryRefresh="onRefreshList"
              />
            </Suspense>
          </template>
        </Card>
      </div>
      <div class="pb-4" :class="showSidebar ? 'pl-5' : ''">
        <InventoryFilterSidebar
          :showSidebar="showSidebar"
          v-model:filters="filtersComputed"
          :warehouses="warehouses"
          :manufacturers="manufacturers"
          :loading="loading"
        />
      </div>
    </div>
  </div>

  <InventoryProductOverview
    v-if="showOverviewDialog && selectedInventoryProductId"
    v-model:showOverviewDialog="showOverviewDialog"
    @update:showOverviewDialog="clearSelectedRow"
    @editInventoryProductQuantity="openEditInventoryQuantity"
    @editInventoryProductCost="openEditInventoryCost"
    :productId="selectedInventoryProductId"
    :warehouseId="selectedWarehouseIdComputed"
  />
  <InventoryProductQuantityEdit
    v-if="showEditQuantityDialog && selectedInventoryProductId"
    v-model:showEditQuantityDialog="showEditQuantityDialog"
    @update:showEditQuantityDialog="clearSelectedRow"
    :productId="selectedInventoryProductId ?? ''"
    :warehouseId="selectedWarehouseIdComputed"
  />

  <InventoryProductCostEdit
    v-if="showEditCostDialog && selectedInventoryProductId"
    v-model:showEditCostDialog="showEditCostDialog"
    @update:showEditCostDialog="clearSelectedRow"
    :productId="selectedInventoryProductId"
    :warehouseId="selectedWarehouseIdComputed"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import InventoryProductOverview from "../components/overview/InventoryProductOverview.vue";
import InventoryProductCostEdit from "../edit/InventoryProductCostEdit.vue";
import InventoryProductQuantityEdit from "../edit/InventoryProductQuantityEdit.vue";
import InventoryFilterSidebar from "../search/InventoryFilterSidebar.vue";
import InventoryProductsHeader from "../search/InventoryProductsHeader.vue";
import { useRoute, useRouter } from "vue-router";

import { useSearch } from "@/repositories/search/SearchService";
import { ProductSearchRequest } from "@/repositories/search/model/product/ProductSearchRequest";
import { SearchProduct } from "@/repositories/search/model/product/SearchProduct";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturerService } from "@/repositories/manufacturer/ManufacturerService";
import { inventoryRoute } from "@/router/routes";
import InventoryProductList from "../search/InventoryProductList.vue";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
const { getAllWarehouses } = useWarehouse();
const { productSearch } = useSearch();
const { getManufacturers } = useManufacturerService();

const router = useRouter();
const inventoryProductList = ref();
const route = useRoute();
const setRouteQuery = (name: string, query?: string) => {
  router.push({ path: route.path, query: { ...route.query, [name]: query } });
};

//Fix for the issue where the selected row is not cleared when the user navigates back to the inventory list
let fixSelectedInventoryProductId = "";

const selectedInventoryProductId = computed<SearchProduct["id"] | undefined>({
  get: () => {
    if (route.params?.id === undefined) {
      return fixSelectedInventoryProductId;
    }

    if (route.params?.id === "") {
      return fixSelectedInventoryProductId;
    }

    return route.params?.id as SearchProduct["id"];
  },
  set: (id) => {
    fixSelectedInventoryProductId = "";
    if (id === undefined || id === "") {
      router.push({ path: inventoryRoute(), query: { ...route.query, modal: undefined } });
    } else {
      router.push({ path: inventoryRoute(id), query: route.query });
      fixSelectedInventoryProductId = id;
    }
  },
});

const clearSelectedRow = () => {
  inventoryProductList.value.clearSelectedRow();
};

const query = ref("");
const page = ref(1);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);
const totalHits = ref(0);
const filters = ref<ProductSearchFilters>(new ProductSearchFilters());
const showSidebar = ref(true);
const loading = ref(false);

const selectWasTriggeredFromEnterKey = (event: DataTableRowSelectEvent) =>
  event.originalEvent instanceof KeyboardEvent && (event.originalEvent as KeyboardEvent).key === "Enter";

const onRowSelected = (event: DataTableRowSelectEvent) => {
  selectedInventoryProductId.value = event.data?.id;
  if (!selectWasTriggeredFromEnterKey(event)) {
    return;
  }

  openInventoryProductOverview();
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onUpdateSortInventoryOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const showOverviewDialog = computed<boolean>({
  get: () => !loading.value && route.query.modal === "view",
  set: (value) => {
    setRouteQuery("modal", value ? "view" : undefined);
    return value;
  },
});
const showEditQuantityDialog = computed<boolean>({
  get: () => {
    const value = route.query?.modal as string; //This line is required to fix setRouteQuery event
    if (!loading.value && value === "edit-quantity") {
      return true;
    }
    return false;
  },
  set: (value) => {
    setRouteQuery("modal", value ? "edit-quantity" : undefined);
    return value;
  },
});
const showEditCostDialog = computed<boolean>({
  get: () => {
    const value = route.query?.modal as string; //This line is required to fix setRouteQuery event
    if (!loading.value && value === "edit-cost") {
      return true;
    }
    return false;
  },
  set: (value) => {
    setRouteQuery("modal", value ? "edit-cost" : undefined);
    return value;
  },
});

const products = ref<SearchProduct[]>([]);
const selectedInventoryProduct = computed<SearchProduct>({
  get: () => {
    return products.value.find((product) => product?.id === selectedInventoryProductId.value) ?? new SearchProduct();
  },
  set: (value) => {
    selectedInventoryProductId.value = value?.id;
  },
});
const warehouses = ref<Warehouse[]>([]);
const manufacturers = ref<Manufacturer[]>([]);

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  selectedInventoryProductId.value = event.data?.id;

  openInventoryProductOverview();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const filtersComputed = computed<ProductSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: ProductSearchFilters) => {
  filters.value = value;
  page.value = 1;
  await search();
};

const openInventoryProductOverview = () => {
  if (selectedInventoryProductId.value === undefined) return;

  showOverviewDialog.value = true;
};

const openEditInventoryQuantity = () => {
  if (selectedInventoryProductId.value === undefined) return;
  showOverviewDialog.value = false;
  showEditQuantityDialog.value = true;
};

const openEditInventoryCost = () => {
  if (selectedInventoryProductId.value !== undefined) {
    showOverviewDialog.value = false;
    showEditCostDialog.value = true;
  }
};

const openInventoryChangesLog = () => {
  router.push({ name: "inventory-changes-log" });
};

const fetchInventoryData = async () => {
  loading.value = true;
  try {
    warehouses.value = await getAllWarehouses();

    // If no warehouse is selected, default to the first warehouse
    if (filters.value.warehouses.length < 1 || filters.value.warehouses[0] === "") {
      filters.value.warehouses = [warehouses.value[0]?.id];
    }

    await search();

    manufacturers.value = await getManufacturers();
  } finally {
    loading.value = false;
  }
};

const selectedWarehouseIdComputed = computed<string>(() => {
  if (filters.value.warehouses.length <= 1 || route.query.warehouse === undefined) {
    return filters.value.warehouses[0] ? filters.value.warehouses[0] : "";
  }
  return route.query.warehouse as string;
});

const search = async () => {
  loading.value = true;

  try {
    const request = new ProductSearchRequest(query.value ? query.value : "*");
    request.includeAvailability = true;
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await productSearch(request);

    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};
onMounted(async () => {
  query.value = route.query.search as string;

  const warehouseId = route.query.warehouse !== undefined ? (route.query.warehouse as string) : "";
  filters.value.warehouses = [warehouseId];

  await fetchInventoryData();
});

watch(
  () => route.query.search,
  async (querySearch) => {
    if (querySearch !== undefined) {
      query.value = querySearch as string;
      page.value = 1;
      await search();
    }
  },
);
</script>

<style scoped lang="scss">
.c-inventory-list {
  margin: var(--default-content-margin);
}
</style>
