<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('picking-list.header')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      :value="filteredPickingLists"
      dataKey="id"
      :autoLayout="true"
      responsiveLayout="scroll"
      selectionMode="single"
      class="c-datatable"
      :loading="loading"
      contextMenu
      v-model:contextMenuSelection="selectedOrdersReadyForPickingComputed"
      @row-select="onRowSelection"
      @row-contextmenu="onRowContextMenu"
      v-model:selection="selectedOrdersReadyForPickingComputed"
      :paginator="true"
      :rows="pageSize"
      :sortOrder="sortOrder"
      :sortField="sortField"
      @sort="onSort"
      @page="onPage"
      v-model:filters="filters"
      filterDisplay="menu"
      showAddButton="false"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      stripedRows
      removableSort
      :resizableColumns="true"
      columnResizeMode="fit"
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      @row-dblclick="onRowDblClicked"
      ref="pickingListSearchResult"
      data-testid="open-picking-lists-table"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
          filteraddbuttoncontainer: { class: 'hidden' },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template v-if="col.field === OpenPickingListColumn.PickingListNumber" #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            :placeholder="t('picking-list.filter.picking-list-number')"
        /></template>
        <template v-else-if="col.field === OpenPickingListColumn.State" #filter="{ filterModel }">
          <Select
            v-model="filterModel.value"
            :options="pickingListState"
            :placeholder="t('picking-list.filter.state')"
            showClear
          >
            <template #option="slotProps">
              <Tag :value="slotProps.option" :severity="severityFromState(slotProps.option)" />
            </template>
          </Select>
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.OrderNumber" #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.order-number')"
        /></template>
        <template v-else-if="col.field === OpenPickingListColumn.FreightMethodName" #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            :placeholder="t('picking-list.filter.freight-method-name')"
        /></template>
        <template v-else-if="col.field === OpenPickingListColumn.CustomerNumber" #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.customer-number')"
        /></template>
        <template v-else-if="col.field === OpenPickingListColumn.CustomerName" #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.customer-name')"
        /></template>
        <template v-else-if="col.field === OpenPickingListColumn.OrderReference" #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.order-reference')"
        /></template>

        <template #body="{ field, data, index }">
          <template v-if="col.field === OpenPickingListColumn.ShippingDate">
            {{ d(data[field], "short") }}
          </template>
          <template v-else-if="col.field === OpenPickingListColumn.PickingListNumber">
            <span
              data-testId="pickinglist-number-text"
              class="c-pickinglist-number-text"
              @click="onPickingListNumberClicked(data, index)"
              >{{ data.pickingListNumber }}</span
            >
          </template>
          <template v-else-if="col.field === OpenPickingListColumn.PickingStarted">
            {{ formatPickingStartedDate(data) }}
          </template>
          <template v-else-if="col.field === OpenPickingListColumn.State">
            <Tag
              :value="t(`picking-list.states.${data.state.toLowerCase()}`)"
              :severity="severityFromState(data.state) as any"
            />
          </template>
          <template v-else-if="col.field === OpenPickingListColumn.SumTotalLines">
            {{ n(data[field], "decimal") }}
          </template>
          <template v-else-if="col.field === OpenPickingListColumn.PDF">
            <div class="c-pdf-icon">
              <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
            </div>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("picking-list.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
  <ContextMenu :model="menuModel" ref="contextMenu" data-testid="picking-list-context-menu" />

  <ConfirmDialog />
  <PickingPrintDialog v-model:visibleDialog="showPrintModal" :pickingList="selectedPickingList" v-if="showPrintModal" />
</template>

<script setup lang="ts">
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { useAuth } from "@cumulus/event-bus";
import { storeToRefs } from "pinia";
import {
  DataTableRowContextMenuEvent,
  DataTableRowSelectEvent,
  DataTableRowDoubleClickEvent,
  DataTableSelectAllChangeEvent,
  DataTablePageEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDocumentService } from "../document/DocumentService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import PickingPrintDialog from "./PickingPrintDialog.vue";

import { OpenPickingListColumn } from "../open-picking-lists/OpenPickingListColumn";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { FilterMatchMode, FilterOperator } from "@primevue/core";

const { t, d, n } = useI18n();
const pickingListState = ref(Object.values(PickingListState));

const pickingListStore = usePickingListStore();
const { getOpenPickingLists } = pickingListStore;
const { getPickingListUrl } = useDocumentService();
const { pickingLists } = storeToRefs(pickingListStore);
const contextMenu = ref();
const query = ref("");
const pickingListSearchResult = ref();
const focusedRow = ref();
const toast = useCumulusToast(useToast());
const showPrintModal = ref(false);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);

const visible = ref(false);

const { getUser } = useAuth();
const loading = ref(true);

const props = defineProps<{
  selectedPickingList: PickingList | undefined;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "showPickingList"): void;
  (e: "onStartPicking"): void;
  (e: "confirmDelete"): void;
  (e: "update:selectedPickingList", value: PickingList | undefined): void;
}>();

const selectedOrdersReadyForPickingComputed = computed({
  get: () => props.selectedPickingList,
  set: (value) => emit("update:selectedPickingList", value),
});

const openPickingListsSearchColumns: DataTableColumn[] = [
  { field: "shippingDate", header: t("picking-list.shipping-date"), sortable: true },
  {
    field: "pickingListNumber",
    header: t("picking-list.picking-list-number"),
    class: "text-right",
    sortable: true,
  },
  { field: "state", header: t("picking-list.state"), class: "text-right", sortable: true },
  {
    field: "assignedEmployeeInitials",
    header: t("picking-list.employee-initials"),
    class: "text-right",
    sortable: true,
  },
  { field: "pickingStarted", header: t("picking-list.picking-started"), sortable: true },
  { field: "orderNumber", header: t("picking-list.order-number"), class: "text-right", sortable: true },
  { field: "freightMethodName", header: t("picking-list.freight-method-name"), sortable: true },
  { field: "customerNumber", header: t("picking-list.customer-number"), class: "text-right", sortable: true },
  { field: "customerName", header: t("picking-list.customer-name"), class: "text-right", sortable: true },
  { field: "orderReference", header: t("picking-list.order-reference"), sortable: true },
  {
    field: "quantityOfOrderItems",
    header: t("picking-list.quantity-of-order-items"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "sumTotalLines",
    header: t("picking-list.sum-of-total-ex-vat"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "PDF",
    header: "PDF",
    class: "text-right",
    sortable: false,
  },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences(
  "openPickingListsSearchColumns",
  openPickingListsSearchColumns,
  null,
  (await getUser()).getEmployee().id,
);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== OpenPickingListColumn.PickingListNumber &&
      col.field !== OpenPickingListColumn.State &&
      col.field !== OpenPickingListColumn.OrderNumber,
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    class: "c-refresh-button",
    command: () => {
      onClickOpenPickingListsRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",
    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const onClickOpenPickingListsRefresh = async () => {
  await getOpenPickingLists();

  nextTick(() => {
    focusSearchResult();
  });
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? openPickingListsSearchColumns
    : openPickingListsSearchColumns.filter(
        (c) =>
          c.field === OpenPickingListColumn.PickingListNumber ||
          c.field === OpenPickingListColumn.State ||
          c.field === OpenPickingListColumn.OrderNumber,
      );
};

const downloadPdf = async (id: string) => {
  try {
    loading.value = true;
    const url = await getPickingListUrl(id);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("print-picking-list.download-link-missing"),
      });
    }
  } finally {
    loading.value = false;
  }
};

const severityFromState = (state: PickingListState): string => {
  switch (state) {
    case PickingListState.Open:
      return "success";
    case PickingListState.InPicking:
      return "";
    case PickingListState.Parked:
      return "warning";
    case PickingListState.Done:
      return "info";
    default:
      return "";
  }
};

const formatPickingStartedDate = (pickingList: PickingList): string => {
  if (pickingList.state === PickingListState.InPicking || pickingList.state === PickingListState.Parked) {
    if (pickingList.pickingStarted !== null) {
      return d(pickingList.pickingStarted, "long");
    }
    // eslint-disable-next-line no-console
    console.error("formatPickingStartedDate is null", pickingList);
  }
  return "";
};

const setFocusedRow = (index: number) => {
  focusedRow.value = pickingListSearchResult.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  setFocusedRow(event.index);
  contextMenu.value.show(event.originalEvent);
};

const selectWasTriggeredFromEnterKey = (event: DataTableRowSelectEvent) =>
  event.originalEvent instanceof KeyboardEvent && (event.originalEvent as KeyboardEvent).key === "Enter";

const onRowSelection = (event: DataTableRowSelectEvent) => {
  selectedOrdersReadyForPickingComputed.value = event.data as PickingList;

  if (!selectWasTriggeredFromEnterKey(event)) return;
  emit("showPickingList");
};

const onPickingListNumberClicked = (pickingList: PickingList, index: number) => {
  setFocusedRow(index);
  selectedOrdersReadyForPickingComputed.value = pickingList;
  emit("showPickingList");
};

const menuModel = ref([
  {
    label: t("picking-list.context-menu.show-picking-list"),
    icon: "pi pi-fw pi-search",
    command: () => emit("showPickingList"),
  },
  {
    label: t("picking-list.context-menu.start-picking"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("onStartPicking"),
  },
  {
    label: t("common.delete"),
    icon: "pi pi-fw pi-trash",
    command: () => emit("confirmDelete"),
  },
  { label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() },
]);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  selectedOrdersReadyForPickingComputed.value = event.data as PickingList;
  emit("showPickingList");
};

const filteredPickingLists = computed<PickingList[]>(() => {
  if (pickingLists.value.length === 0) {
    return [];
  }
  return pickingLists.value.filter((picking: PickingList) => {
    return (
      d(picking.shippingDate).includes(query.value.toLowerCase()) ||
      picking.orderNumber.toString().startsWith(query.value.trim()) ||
      picking.pickingListNumber.toString().startsWith(query.value.trim())
    );
  });
});

const focusSearchResult = () => {
  if (pickingLists.value.length > 0) {
    pickingListSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const createFilter = (matchMode: string) => ({
  operator: FilterOperator.AND,
  constraints: [{ value: "", matchMode }],
});

const filters = ref({
  pickingListNumber: createFilter(FilterMatchMode.STARTS_WITH),
  state: createFilter(FilterMatchMode.CONTAINS),
  orderNumber: createFilter(FilterMatchMode.STARTS_WITH),
  freightMethodName: createFilter(FilterMatchMode.STARTS_WITH),
  customerNumber: createFilter(FilterMatchMode.STARTS_WITH),
  customerName: createFilter(FilterMatchMode.STARTS_WITH),
  orderReference: createFilter(FilterMatchMode.STARTS_WITH),
});

onMounted(async () => {
  loading.value = true;
  try {
    await getOpenPickingLists();

    nextTick(() => {
      focusSearchResult();
    });
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.c-picking-list-table {
  :deep(.p-datatable-thead > tr > .text-right > .p-column-header-content > .p-column-title) {
    width: 100%;
    text-align: right;
  }
}
.c-picking-list {
  margin: var(--default-content-margin);
}
.c-picking-list .card {
  overflow: auto;
}
.c-pickinglist-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
  @media (max-width: 992px) {
    .p-paginator-first {
      margin: 0;
    }
    .p-paginator-current {
      display: none;
    }
  }
}

// @media (max-width: 992px) {
//   .c-card {
//     margin: 0;
//     border-radius: 0;
//     padding: 1rem;
//   }
// }

.c-multiselect-wrapper {
  position: absolute;
  top: 160px;
  right: 40px;
  z-index: 9;
  @media (max-width: 767px) {
    top: 100px;
    right: 10px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 95px;
    right: 16px;
  }
}

:deep(.p-multiselect) {
  border: none;
}
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
</style>
