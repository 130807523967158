<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('picking-lines.header')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      v-if="pickingList !== undefined"
      :value="pickingList.lines"
      dataKey="id"
      :autoLayout="true"
      stripedRows
      :rowClass="rowClass"
      :resizableColumns="true"
      columnResizeMode="fit"
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      removableSort
      class="c-datatable"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ field, data }">
          <template v-if="col.field === PickingLinesColumn.PositionNumber">
            <span>{{ data.positionNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.ProductNumber">
            <span>{{ data.productNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.SupplierProductNumber">
            <span>{{ data.supplierProductNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.Gtin">
            <span>{{ data.gtin }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.ProductName">
            <span>{{ data.productName }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.BatchNumber">
            <span>{{ data.batchNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityForPicking">
            <div data-testid="picking-list-quantity-for-picking">{{ data.quantityForPicking }}</div>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityPicked">
            <div v-if="!editMode" data-testid="picking-list-quantity-picked">{{ data.quantityPicked }}</div>
            <div v-else>
              <InputNumber
                v-model="data.quantityPicked"
                data-testid="picking-list-quantity-picked-input"
                :min="0"
                :max="data.quantityForPicking"
                :allowEmpty="true"
                @blur="onSavePickingProgress(data)"
                inputClass="w-20"
              ></InputNumber>
            </div>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityRemaining">
            <div data-testid="picking-list-quantity-remaining">{{ calculateQuantityRemaining(data) }}</div>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("picking-list.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
</template>

<script setup lang="ts">
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { PickingListLine } from "@/repositories/picking-list/model/PickingListLine";
import { useI18n } from "vue-i18n";
import { computed, nextTick, ref } from "vue";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";
import { storeToRefs } from "pinia";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingLinesColumn } from "../open-picking-lists/PickingLinesColumn";
import { DataTableSelectAllChangeEvent } from "primevue/datatable";

const pickingListStore = usePickingListStore();
const { saveProgress } = pickingListStore;
const { loading } = storeToRefs(pickingListStore);
const { t } = useI18n();
const { getUser } = useAuth();

const visible = ref(false);

const props = defineProps<{
  pickingList: PickingList;
  editMode: boolean;
}>();

const pickingLinesSearchColumns: DataTableColumn[] = [
  { field: "positionNumber", header: t("picking-list.position-number"), sortable: true },
  { field: "productNumber", header: t("picking-list.product-number"), sortable: true },
  { field: "supplierProductNumber", header: t("picking-list.supplier-product-number"), sortable: true },
  { field: "gtin", header: t("picking-list.gtin"), sortable: true },
  { field: "productName", header: t("picking-list.product-name"), sortable: true },
  { field: "batchNumber", header: t("picking-list.batch-number"), sortable: false },
  { field: "quantityForPicking", header: t("picking-list.quantity-for-picking"), sortable: true },
  { field: "quantityPicked", header: t("picking-list.quantity-picked"), class: "w-20 p-1", sortable: true },
  { field: "quantityRemaining", header: t("picking-list.quantity-remaining"), class: "w-20 p-1", sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences(
  "pickingLinesSearchColumns",
  pickingLinesSearchColumns,
  null,
  (await getUser()).getEmployee().id,
);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) => col.field !== PickingLinesColumn.ProductNumber && col.field !== PickingLinesColumn.Gtin,
  );
});

const items = [
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",
    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? pickingLinesSearchColumns
    : pickingLinesSearchColumns.filter(
        (c) => c.field === PickingLinesColumn.ProductNumber || c.field === PickingLinesColumn.Gtin,
      );
};

const calculateQuantityRemaining = (line: PickingListLine) => {
  if (line.quantityPicked === undefined || line.quantityPicked === null) {
    return line.quantityForPicking;
  }

  return line.quantityForPicking - line.quantityPicked;
};

const rowClass = (line: PickingListLine) => {
  if (line.quantityPicked == null) return;

  if (props.pickingList.state !== PickingListState.Open) {
    if (line.quantityPicked === line.quantityForPicking) {
      return "c-line-done";
    } else if (isNumber(line.quantityPicked)) {
      return "c-line-partial";
    }
  }
  return "";
};
const isNumber = (val: number | null) => typeof val === "number";

const onSavePickingProgress = async (line: PickingListLine) => {
  nextTick(async () => {
    if (line.quantityPicked === null) {
      return;
    }

    if (line.quantityPicked > line.quantityForPicking) {
      line.quantityPicked = line.quantityForPicking;
    }

    if (line.quantityPicked < 0) {
      line.quantityPicked = 0;
    }

    await saveProgress(props.pickingList.id, line.id, line.quantityPicked);
  });
};
</script>

<style lang="scss">
.p-datatable .p-datatable-tbody > tr.c-line-done {
  background-color: var(--picking-list-picked);
}
.c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.c-line-done {
  background-color: var(--picking-list-picked);
}
.c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.c-line-partial {
  background-color: var(--picking-list-partially-picked);
}
</style>
