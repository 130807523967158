<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        ref="inputRef"
        id="discount-name"
        data-testid="discount-name"
        v-model="nameComputed"
        class="w-full"
        :invalid="val.name.$error"
        maxlength="8000"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('discount.name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="discount-name">{{ t("discount.name") }}</label>
    </FloatLabel>
    <small class="p-error" v-if="val.$error" data-testid="discount-name-error">
      {{ val.name.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { required } from "../../i18n-validators";
import { useI18n } from "vue-i18n";

const inputRef = ref();

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const { t } = useI18n();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value: string) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, props);

const focus = () => {
  if (inputRef.value) {
    inputRef.value.$el.focus();
  }
};

onMounted(focus);
</script>
