import { httpClient, AuthHeaders } from "@cumulus/http";
import { CityResponse } from "./model/CityResponse";
import { i18n } from "@/locales/i18n";
import { Country } from "./model/Country";
import { WritableComputedRef } from "vue";

class CountryRepository {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY
        : (import.meta.env.VITE_APP_API_URL as string);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Country[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/countries/bylocale/${(i18n.global.locale as WritableComputedRef<string>).value}`)
      .then(({ data }) => data);
  }

  public async getCity(authHeaders: AuthHeaders, code: string): Promise<CityResponse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/postal/code/" + code)
      .then(({ data }) => data);
  }
}

const countryRepository = new CountryRepository();
export { countryRepository };
