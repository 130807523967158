<template>
  <DataTable
    ref="customerTableRef"
    :value="customers"
    scrollHeight="flex"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :loading="loading"
    :rowHover="true"
    data-testid="customer-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :lazy="true"
    :rows="pageSize"
    :totalRecords="totalHits"
    class="c-datatable"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :currentPageReportTemplate="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
    @sort="onSort"
    @page="onPage"
  >
    <template>
      <Column :header="t('creditnote.customer.business')" class="c-col-image">
        <template #body="{ data }">
          <i class="pi" :class="data.customerType === CustomerType.Business ? 'true-icon pi-check-circle' : ''"></i>
        </template>
      </Column>

      <Column field="customerNumber" :header="t('creditnote.customer.customer-number')"></Column>
      <Column field="name" :header="t('creditnote.customer.customer-name')"></Column>
      <Column field="deliveryAddress.city" :header="t('creditnote.customer.city')"></Column>
      <Column field="phoneNumber" :header="t('creditnote.customer.phone-number')"></Column>
      <Column field="email" :header="t('creditnote.customer.email')"></Column>
    </template>

    <template #loading>{{ t("common.loading") }}</template>

    <template #empty>
      {{ t("creditnote.no-customers-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DataTablePageEvent, DataTableRowSelectEvent, DataTableSortEvent } from "primevue/datatable";
import { CustomerType } from "@/models/customer/CustomerType";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";

const { t } = useI18n();

const props = defineProps<{
  customers: SearchCustomer[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "customerSelected", value: SearchCustomer): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
}>();

const customerTableRef = ref();

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  emit("customerSelected", event.data);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>
