import {
  BringShippingGuideRequest,
  Consignment,
  Package,
  Product as RequestProduct,
} from "@/models/bring/BringShippingGuideRequest";
import { Parcel } from "@/models/bring/Parcel";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { v4 as uuid } from "uuid";
import { useI18n } from "vue-i18n";
import { Order } from "@/models/order/Order";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { useBring } from "@/models/bring/BringService";
import { BringShippingGuideResponse } from "@/models/bring/BringShippingGuideResponse";

export const useBringStore = defineStore("bringStore", () => {
  const bringService = useBring();
  const { locale } = useI18n();

  const bringPriceExVat = ref<number>(0);
  const bringPriceIncVat = ref<number>(0);
  const shippingGuideResponse = ref<BringShippingGuideResponse>();
  const parcels = ref<Parcel[]>([new Parcel(1)]);

  const shippingGuideErrors = computed(() => shippingGuideResponse.value?.consignments[0].products[0].errors ?? []);
  const weight = computed(() => parcels.value.reduce((acc, parcel) => acc + parcel.weight, 0));

  const getLanguageFromLocale = (locale: string) => {
    //Read the locale and return the langueage code in format NO, EN, DK, SE
    switch (locale) {
      case "en-GB":
        return "EN";
      case "nb-NO":
        return "NO";
      default:
        return "NO";
    }
  };

  const createBringShippingGuideRequest = (currentOrder: Order, warehouse: Warehouse) => {
    const shippingGuideRequest = new BringShippingGuideRequest();
    shippingGuideRequest.withGuiInformation = true;
    shippingGuideRequest.language = getLanguageFromLocale(locale.value);

    const consignment = new Consignment();
    consignment.id = uuid();
    //We only support norway currently
    consignment.fromCountryCode = consignment.toCountryCode = "NO";
    consignment.toPostalCode = currentOrder.delivery.address.postalCode;
    consignment.fromPostalCode = warehouse?.address?.postalCode ?? "";
    consignment.packages = parcels.value.map((parcel) => new Package().fromParcel(parcel));

    shippingGuideRequest.consignments = [consignment];

    const product = new RequestProduct();
    product.id = currentOrder.freightMethod.bring?.bringShippingService.requestCode ?? "";
    product.customerNumber = currentOrder.freightMethod.bring?.bringServiceAgreement.customerNumber ?? "";

    consignment.products = [product];
    return shippingGuideRequest;
  };

  const getBringShippingGuide = async (currentOrder: Order, warehouse: Warehouse) => {
    const shippingGuideRequest = createBringShippingGuideRequest(currentOrder, warehouse);
    shippingGuideResponse.value = await bringService.getBringShippingGuide(shippingGuideRequest);

    if (shippingGuideResponse.value != null) {
      setShippingPriceFromBring(shippingGuideResponse.value);
    }
  };

  const shippingGuideResponseHasErrors = computed(() => {
    if (shippingGuideResponse.value == null) return false;

    return (shippingGuideResponse.value.consignments?.[0]?.products?.[0]?.errors?.length ?? [].length > 0)
      ? true
      : false;
  });

  const setShippingPriceFromBring = (bringResponse: BringShippingGuideResponse) => {
    if (shippingGuideResponse.value == null) return null;
    if (shippingGuideResponseHasErrors.value) return null;

    const priceExVat =
      bringResponse.consignments?.[0]?.products?.[0]?.price?.listPrice?.priceWithAdditionalServices?.amountWithoutVAT;
    const priceIncVat =
      bringResponse.consignments?.[0]?.products?.[0]?.price?.listPrice?.priceWithAdditionalServices?.amountWithoutVAT;

    if (priceExVat == null || isNaN(parseFloat(priceExVat)) || priceIncVat == null || isNaN(parseFloat(priceIncVat))) {
      throw new Error("Unexpected response from Bring API");
    }

    bringPriceExVat.value = parseFloat(priceExVat);
    bringPriceIncVat.value = parseFloat(priceIncVat);
  };

  return {
    bringPriceExVat,
    bringPriceIncVat,
    shippingGuideErrors,
    weight,
    parcels,
    getBringShippingGuide,
    createBringShippingGuideRequest,
  };
});
