<template>
  <FloatLabel variant="on">
    <Select
      id="payment-term"
      data-testId="default-payment-term"
      v-model="selectedPaymentTerm"
      :options="dropdownOptions"
      optionLabel="name"
      optionValue="value"
      :class="{ 'p-invalid': val.paymentTerm.$error, 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      :isRequired="true"
      :fluid="true"
    />
    <label for="payment-term" class="c-required">{{ t("purchase.payment-term") }}</label>
  </FloatLabel>

  <small class="ml-6 p-error" v-if="val.$error" data-testid="default-payment-term-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { usePaymentTerms } from "@/api/payment-term/PaymentTermService";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { PurchaseOrderPaymentTerm } from "@/models/purchase-order/PurchaseOrderPaymentTerm";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { getPaymentTerms } = usePaymentTerms();

const props = defineProps<{
  paymentTerm: PurchaseOrderPaymentTerm;
  paymentTermIdFromSupplier: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:paymentTerm", value: PurchaseOrderPaymentTerm): void;
}>();

const dropdownOptions = computed(() => {
  return paymentTerms.value.map((paymentTerm) => {
    return { name: paymentTerm.name, value: paymentTerm.id };
  });
});

const selectedPaymentTerm = computed<string>({
  get: () => {
    return paymentTerms.value.find((pm) => pm.id === props.paymentTerm?.id)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const pm = paymentTerms.value.find((pm) => pm.id === id);
    if (pm && allowEdit.value === true) {
      emit("update:paymentTerm", new PurchaseOrderPaymentTerm(pm.id, pm.name, pm.creditDays));
    }
  },
});

watch(
  () => props.paymentTermIdFromSupplier,
  (id: string) => (selectedPaymentTerm.value = id)
);

const paymentTerms = ref<PaymentTerm[]>([]);
const loadPaymentTerms = async () => {
  paymentTerms.value = await getPaymentTerms();
};

onMounted(loadPaymentTerms);

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});

const rules = {
  paymentTerm: {
    name: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);
const { t } = useI18n();
</script>
