import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/theme/default";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Select from "primevue/select";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import FileUpload from "primevue/fileupload";
import Card from "primevue/card";
import FloatLabel from "primevue/floatlabel";
import Tooltip from "primevue/tooltip";

import vueDebounce from "vue-debounce";

const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        //prefix: "m",
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Toast", Toast);
  app.component("Checkbox", Checkbox);
  app.component("PrimeButton", Button);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("PrimeDialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("FileUpload", FileUpload);
  app.component("Select", Select);
  app.component("Card", Card);
  app.component("FloatLabel", FloatLabel);

  app.directive("debounce", vueDebounce({ lock: true }));
  app.directive("tooltip", Tooltip);
}
