<template>
  <div class="flex items-center gap-2 mb-6">
    <div class="flex-1 text-color font-semibold leading-6">
      <PrimeButton
        class="c-circular-button mr-4"
        @click="router.push({ name: 'customer-add' })"
        data-testid="btn-commit"
        id="btn-commit"
        style="border-color: var(--p-button-outlined-secondary-border-color)"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4 font-normal">{{ t("customer.new-customer") }}</span>
      </PrimeButton>
    </div>
    <div>
      <PrimeButton
        data-testid="btn-selected-icon"
        @click="openSortList"
        severity="secondary"
        variant="outlined"
        :class="{ 'c-toolbar-button-active': isShowedSorting }"
      >
        <i :class="selectedIcon"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton data-testid="c-refresh-button" @click="emit('refreshList')" severity="secondary" variant="outlined">
        <i class="pi pi-refresh"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton
        data-testid="btn-column-chooser"
        @click="openColumnChooser"
        severity="secondary"
        variant="outlined"
        :class="{ 'c-toolbar-button-active': isShowedChooser }"
      >
        <i class="pi pi-list"></i>
      </PrimeButton>
    </div>
    <div class="ml-2">
      <PrimeButton
        data-testid="btn-filter"
        @click="emit('onToggleFilterSidebar')"
        severity="secondary"
        variant="outlined"
        :class="{ 'c-toolbar-button-active': props.visible }"
      >
        <i class="pi pi-filter"></i>
      </PrimeButton>
    </div>
  </div>
  <div class="flex items-left gap-2 my-4">
    <CustomerActiveFilters v-model:filters="filtersComputed" :customerGroups="customerGroupStore.customerGroups" />
  </div>
  <div>
    <DataTable
      ref="customerSearchResult"
      :value="customers"
      v-model:expandedRows="expandedRows"
      :autoLayout="true"
      dataKey="id"
      selectionMode="single"
      responsiveLayout="scroll"
      @row-select="onRowSelected"
      @row-dblclick="onRowDoubleClick"
      v-model:selection="selectedCustomer"
      :loading="loading"
      :sortOrder="sortOrder"
      :sortField="sortField"
      class="c-datatable"
      :lazy="true"
      :paginator="true"
      :totalRecords="totalHits"
      :stripedRows="true"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowClass="addGlobalSearchClass"
      @sort="onSort"
      @page="onPage"
      tabindex="0"
      data-testid="customer-list"
      scrollable
      :scrollHeight="'calc(100vh - 225px)'"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="t(col.header)"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === CustomerLinesColumns.RegisteredDate">
            <div class="flex justify-between items-center">
              <span>{{ d(data.registeredDate, "short") }}</span>
            </div>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerNumber">
            <span class="c-customer-number-text" @click="onCustomerNumberClicked(data)">
              {{ data.customerNumber }}
            </span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerType">
            <span>{{ data.customerType }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.Name">
            <span>{{ data.name }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.PhoneNumber">
            <span>{{ data.phoneNumber }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.Email">
            <span>{{ data.email }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.DeliveryAddressLine1">
            <span>{{ data.deliveryAddressLine1 }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.DeliveryAddressCity">
            <span>{{ data.deliveryAddressCity }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressCountry">
            <span>{{ data.addressCountry }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressLine1">
            <span>{{ data.addressLine1 }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressCity">
            <span>{{ data.addressCity }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactName">
            <span>{{ data.firstContactName }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactEmail">
            <span>{{ data.firstContactEmail }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactPhone">
            <span>{{ data.firstContactPhone }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerGroups">
            <span>{{ data.customerGroups }}</span>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #empty>
        <div v-if="!loading">{{ t("customer.search.empty-list") }}</div>
      </template>
      <template #loading>{{ t("customer.search.loading-list") }}</template>
    </DataTable>

    <Popover ref="op" data-testid="customer-sort-list">
      <div class="flex flex-col gap-4 w-[14rem] p-2">
        <div>
          <span class="font-bold block mb-2 underline">{{ t("customer.search.sorting-by") }} </span>
          <ul class="list-none p-2 m-0">
            <li
              v-for="column in customerSearchColumns"
              :key="column.field"
              class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
              :class="[
                'transition-colors duration-200',
                {
                  'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                  'hover:bg-emphasis': !isSelectedColumn(column),
                },
              ]"
              @click="onSelectedRow(column)"
            >
              <div class="flex-1">
                <span class="font-medium">{{ t(column.header) }}</span>
              </div>
              <div class="text-right">
                <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Popover>

    <Popover ref="chooserRef" data-testid="customer-column-list">
      <DataTable
        v-model:selection="selectedColumnsComputed"
        :value="filteredColumns"
        dataKey="field"
        tableStyle="min-width: 16rem"
        responsiveLayout="stack"
        breakpoint="991px"
        selectionMode="multiple"
        :metaKeySelection="false"
        :selectAll="selectAll"
        @select-all-change="onSelectAllChange"
        @row-select="onColumnSelected($event.data)"
        @row-unselect="onColumnUnselected($event.data)"
      >
        <div class="card flex justify-center">
          <div class="flex flex-col gap-4">
            <Column selectionMode="multiple" />
            <Column field="header">
              <template #body="{ data }">
                <span data-testid="column-value-row">{{ t(data.header) }}</span>
              </template>
            </Column>
          </div>
        </div>
      </DataTable>
      <div class="flex justify-end">
        <PrimeButton
          type="button"
          :label="t('common.reset')"
          class="w-full m-4"
          severity="danger"
          text
          data-testid="reset-column-button"
          @click="resetColumns"
          autoOptionFocus="false"
          :tabindex="-1"
        ></PrimeButton>
      </div>
    </Popover>
  </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute, LocationQueryValue } from "vue-router";
import { useToast } from "primevue/usetoast";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { NIL as emptyUuid } from "uuid";
import { ListCustomer } from "@/models/search/customer/ListCustomer";
import { useAuth } from "@cumulus/event-bus";
import { CustomerLinesColumns } from "@/models/search/customer/CustomerLinesColumns";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import CustomerActiveFilters from "./search/CustomerActiveFilters.vue";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import { SearchFilters } from "@/models/search/customer/SearchFilters";

const { getUser } = useAuth();
const { t, d } = useI18n();

const router = useRouter();
const route = useRoute();

const toast = useCumulusToast(useToast());
const selectedCustomer = ref<SearchCustomer>(new SearchCustomer());
const expandedRows = ref([]);
const customerSearchResult = ref();
const customerGroupStore = useCustomerGroupStore();

const props = defineProps<{
  customers: ListCustomer[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  visible: boolean;
  filters: SearchFilters;
}>();

const emit = defineEmits<{
  //(e: "openCustomer", value: SearchCustomer): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "refreshList"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
}>();

const customerSearchColumns = [
  { field: "registeredDate", header: "customer.created-date", sortable: true },
  { field: "customerNumber", header: "customer.customer-number.label", sortable: true },
  { field: "customerType", header: "customer.search.header-business-customer", sortable: true },
  { field: "name", header: "customer.search.header-name", sortable: true },
  { field: "phoneNumber", header: "customer.search.header-phone", sortable: true },
  { field: "email", header: "customer.search.header-email", sortable: true },
  {
    field: "deliveryAddressLine1",
    header: "customer.search.header-delivery-address",
    sortable: true,
  },
  { field: "deliveryAddressCity", header: "customer.search.header-delivery-city", sortable: true },
  { field: "addressCountry", header: "customer.search.header-country", sortable: true, class: "w-4" },
  { field: "addressLine1", header: "customer.search.header-address", sortable: true },
  { field: "addressCity", header: "customer.search.header-city", sortable: true },
  { field: "firstContactName", header: "customer.search.header-contact-name", sortable: true },
  { field: "firstContactEmail", header: "customer.search.header-contact-email", sortable: true },
  { field: "firstContactPhone", header: "customer.search.header-contact-phone", sortable: true },
  { field: "customerGroups", header: "customer.search.header-groups", sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("customerSearch", customerSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

watch([() => props.customers, () => props.loading], ([customers, loading]) => {
  if (loading === false && customers.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult(route.query?.focusResult ?? "");
      }
    });
  }
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(customerSearchColumns.find((column) => column.field === CustomerLinesColumns.Name));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const isShowedSorting = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isShowedChooser = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: SearchFilters) => {
    emit("update:filters", value);
  },
});

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = customerSearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: SearchCustomer) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedCustomer.value?.id === data?.id },
  ];
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? customerSearchColumns
    : customerSearchColumns.filter(
        (c) =>
          c.field === CustomerLinesColumns.RegisteredDate ||
          c.field === CustomerLinesColumns.CustomerNumber ||
          c.field === CustomerLinesColumns.Name
      );
};

const openSearchCustomer = (searchCustomer: SearchCustomer) => {
  searchCustomer != null && searchCustomer.id !== emptyUuid
    ? router.push({ name: "customer-edit", params: { id: searchCustomer.id } })
    : toast.add({
        severity: "warn",
        summary: t("customer.toast.open-customer-failed"),
        detail: t("customer.toast.no-customer-selected"),
      });
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchCustomer(event.data);
};

const onRowDoubleClick = (event: DataTableRowDoubleClickEvent) => {
  const searchCustomer = event.data as SearchCustomer;
  if (searchCustomer != null) {
    openSearchCustomer(searchCustomer);
  }
};

const focusSearchResult = (id: LocationQueryValue[] | string = "") => {
  if (props.customers.length > 0 && customerSearchResult.value) {
    const element = id === "" ? null : customerSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    customerSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onPage = (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onCustomerNumberClicked = (customer: SearchCustomer) => {
  selectedCustomer.value = customer;
  openSearchCustomer(customer);
};
</script>

<style scoped lang="scss">
.c-multiselect-wrapper {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 9;
}

.c-customer-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
