<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12">
      <PrimeButton
        icon="pi pi-arrow-left"
        @click="routeToCustomerList"
        data-testid="btn-cancel"
        id="btn-cancel"
        rounded
        size="small"
        variant="text"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800"> {{ customer.name }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center">
      <SelectButton
        class="c-tabs"
        :allowEmpty="false"
        v-model="selectedTab"
        :options="tabOptions"
        :pt="{ label: 'w-full' }"
      >
        <template #option="slotProps">
          {{ t(`customer.tabs.${slotProps.option}`) }}
        </template>
      </SelectButton>
    </div>

    <div
      class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"
      id="teleport-edit-customer-toolbar"
    ></div>
  </div>

  <template v-if="selectedTab === 'overview'">
    <CustomerOverview />
  </template>

  <template v-if="selectedTab === 'details'">
    <CustomerEdit />
  </template>

  <template v-if="selectedTab === 'orders'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'invoices'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'credit-notes'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import CustomerOverview from "../components/overview/CustomerOverview.vue";
import CustomerEdit from "../components/details/CustomerEdit.vue";
import { useCustomerStore } from "@/stores/CustomerStore";
import { Customer } from "@/models/customer/Customer";

const selectedTab = ref("");
const tabOptions = ref(["overview", "details", "orders", "invoices", "credit-notes"]);

const { t } = useI18n();

const { getCustomer } = useCustomerStore();
const customer = ref(new Customer());

const fetchCustomerData = async () => {
  const customerId = route.params.id as string;
  customer.value = await getCustomer(customerId);
};

const route = useRoute();
const router = useRouter();

const currentUrlTab = ref("");

onMounted(async () => {
  currentUrlTab.value = (route.query.tab as string) ?? "overview";
  selectTab(currentUrlTab.value);

  await fetchCustomerData();
});

const selectTab = (tabName: string) => {
  switch (tabName) {
    case "overview":
    case "details":
    case "orders":
    case "invoices":
    case "credit-notes":
      break;
    default:
      tabName = "overview";
      break;
  }

  selectedTab.value = tabName;
};

watch(
  () => selectedTab.value,
  (tab: string) => {
    if (tab === currentUrlTab.value) return;

    //Load all data after changes
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        tab: tab,
      },
    });
  }
);

const routeToCustomerList = () => {
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", customer.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
</script>

<style scoped lang="scss">
:deep(.c-tabs) .p-togglebutton {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

:deep(.c-tabs) .p-togglebutton.p-togglebutton-checked {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #4d7694;
  border-radius: 0;
}

:deep(.c-tabs) .p-togglebutton.p-togglebutton-checked::before {
  background-color: transparent;
  box-shadow: none;
}
</style>
