import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { defineStore } from "pinia";
import { ref } from "vue";
import { ProductImportInitializeResponse, productImportApi } from "./ProductImportApi";

export const useProductImportStore = defineStore("productImportstore", () => {
  const importId = ref<string>("");
  const columns = ref<string[]>([]);
  const rows = ref<string[][]>([]);

  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const initializeImport = async (formData: FormData): Promise<ProductImportInitializeResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      const response = await productImportApi.initializeImport(authHeaders, formData);
      importId.value = response.id;
      columns.value = response.columns;
      rows.value = response.rows;
      return response;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    importId,
    columns,
    rows,
    initializeImport,
  };
});
