<template>
  <FloatLabelInput
    id="customer-number"
    v-model:value="customerNumberComputed"
    data-testid="customer-number"
    :maxlength="500"
    :isRequired="false"
    :isInvalid="val.$error"
    :class="{ 'c-invalid': isCustomerNumberInUse }"
    v-debounce:400="checkIfCustomerNumberIsInUse"
    :label="t('customer.search.header-number')"
    @focus="inputHasFocus = true"
  />

  <small class="p-error" v-if="val.$error" data-testid="customer-number-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useCustomerStore } from "@/stores/CustomerStore";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  customerNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:customerNumber", value: string): void;
  (event: "blur", value: string): void;
}>();

const { checkCustomerNumberUsed } = useCustomerStore();
const isCustomerNumberInUse = ref<boolean>(false);
const isChecking = ref<boolean>(false);

const customerNumberComputed = computed<string>({
  get: () => {
    return props.customerNumber ?? "";
  },
  set: (value) => {
    emit("update:customerNumber", value);
  },
});

const { t } = useI18n();

const checkIfCustomerNumberIsInUse = async () => {
  if (customerNumberComputed.value === "") {
    isCustomerNumberInUse.value = false;
    return;
  }
  isChecking.value = true;
  isCustomerNumberInUse.value = await checkCustomerNumberUsed(customerNumberComputed.value);
  isChecking.value = false;
};

const inputHasFocus = ref(false);

const rules = {
  isCustomerNumberInUse: {
    customerNumberAvailable: helpers.withMessage(
      t("validations.customer-number-already-in-use"),
      (value: boolean) => !value
    ),
  },
};

const val = useVuelidate(rules, { isCustomerNumberInUse });

defineExpose({
  focus,
});
</script>
