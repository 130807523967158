<template>
  <Teleport v-if="isMobile" to="#c-mobile-toolbar">
    <PrimeButton class="p-button-text" data-testid="btn-cancel-mobile" @click="emit('onCancel')">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.cancel") }}</span>
    </PrimeButton>
    <PrimeButton
      class="p-button-text"
      data-testid="btn-save-mobile"
      :disabled="!props.isOrderChanged || props.isSaving"
      @click="emit('onCommit')"
    >
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.save") }}</span>
    </PrimeButton>
  </Teleport>

  <Teleport v-if="isMobile" to="#c-more-options-menu">
    <PrimeButton class="p-button-text" data-testid="btn-sidebar" @click="emit('toggleSidebarClicked')">
      <i class="pi pi-info"></i>
      <span class="c-mobile-toolbar-text">{{ props.showSidebar ? "Hide panel" : "Show panel" }}</span>
    </PrimeButton>
  </Teleport>

  <div class="c-toolbar-wrapper bg-surface-100">
    <div class="flex flex-wrap">
      <div class="min-w-full lg:min-w-0 lg:w-5/12 content-center">
        <PrimeButton
          id="btn-cancel"
          icon="pi pi-arrow-left"
          :disabled="props.isSaving"
          data-testid="btn-cancel"
          rounded
          size="small"
          variant="text"
          @click="emit('onCancel')"
        />
        <div class="inline-block ml-4 text-lg">
          <span class="text-slate-800">{{ editMode ? t("order.edit-order") : t("common.add") }}</span>
        </div>
      </div>

      <div class="min-w-full lg:min-w-0 lg:w-1/12 mb-4 lg:mb-0 text-center content-center"></div>

      <div class="min-w-full lg:min-w-0 lg:w-6/12 mb-2 lg:mb-0 text-center lg:text-right content-center">
        <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
          <PrimeButton
            v-if="allowSave"
            id="btn-commit"
            class="c-circular-button mr-4"
            :disabled="!props.isOrderChanged || props.isSaving"
            data-testid="btn-save"
            @click="emit('onCommit')"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>

        <PrimeButton
          v-if="allowDelete"
          class="c-circular-button mr-4"
          data-testid="btn-delete"
          :disabled="props.isSaving"
          @click="onConfirmDelete"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>

        <PrimeButton class="c-circular-button" data-testid="btn-sidebar" @click="emit('toggleSidebarClicked')">
          <i class="pi pi-clone c-default-button c-circular-icon"></i>
          <span class="px-3">{{ props.showSidebar ? t("order.hide-panel") : t("order.show-panel") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="props.unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToOrderSearch')"
    @saveClicked="emit('onCommit')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { OrderStatus } from "@/models/order/OrderStatus";
import { computed } from "vue";
import { UnsavedChangesDialog } from "@cumulus/components";
import { useMobile } from "@/utils/MobileService";

const { t } = useI18n();
const confirm = useConfirm();
const { isMobile } = useMobile();

const props = defineProps<{
  showSidebar: boolean;
  editMode: boolean;
  unsavedChangesDialogVisible: boolean;
  isOrderChanged: boolean;
  isSaving: boolean;
  orderStatus: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "onCommit"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
  (e: "stayOnPage"): void;
  (e: "routeToOrderSearch"): void;
  (e: "toggleSidebarClicked"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("order.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const allowDelete = computed<boolean>(() => {
  return props.editMode && props.orderStatus === OrderStatus.Open;
});

const allowSave = computed<boolean>(() => {
  return (props.editMode && props.orderStatus !== OrderStatus.Delivered) || !props.editMode;
});

const saveButtonTooltip = computed(() => {
  return props.isOrderChanged ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-tool-material .material-symbols-outlined {
  font-size: 20px;
  padding: 2px;
  font-weight: 300;
}

.c-header-icon-container {
  background: transparent;
}

.c-circular-button:disabled {
  opacity: 0.6;
}

.c-toolbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  height: 3.5rem;
  align-content: center;
}
</style>
