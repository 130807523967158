<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <DiscountHeader
    :isSaving="isSaving"
    :isEditing="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @saveClicked="addNewDiscount"
    @cancelClicked="onCancel"
    @dialogCancelBtnClicked="stayOnPage"
    @dialogDiscardBtnClicked="routeToSearchPage"
    :collapsedPanels="panelCollapsed"
    @onToggleAllPanels="onToggleAllPanels()"
  />

  <div class="c-overlay" v-if="loading">
    <div class="c-spinner-container">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-discount-margin">
    <div>
      <div class="c-content mb-6">
        <div class="c-content-top">
          <div class="c-content-top-left">
            <Card class="c-card-font mr-4">
              <template #content>
                <div class="grid grid-cols-2 gap-4 pb-2">
                  <div>
                    <Name v-model:name="discount.name" />
                  </div>
                  <div>
                    <Description v-model:description="discount.description" />
                  </div>
                  <div>
                    <StatusCheckbox
                      id="discount-active"
                      :label="t(`discount.active`)"
                      v-model:value="discount.active"
                    />
                  </div>
                  <div>
                    <div class="grid grid-cols-3 gap-4 pb-3">
                      <StatusCheckbox
                        :label="t(`discount.active-client`)"
                        id="discount-active-for-client"
                        v-model:value="discount.activeForClient"
                      />
                      <SelectClient v-model:clientId="discount.clientId" />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>

          <div class="c-content-top-right">
            <Card class="c-card-font mb-4">
              <template #content>
                <div class="grid grid-cols-2 gap-4 pb-3">
                  <div>
                    <SelectDiscountType v-model:type="discount.discountType" />
                  </div>
                  <div>
                    <SelectDiscountRule
                      v-model:rule="discount.discountRule"
                      @discountRuleChanged="onDiscountRuleChanged"
                    />
                  </div>
                  <div>
                    <Date
                      v-model:dateTime="discount.activeFrom"
                      id="active-from"
                      :label="t('discount.active-from')"
                      dataTestId="discount-active-from-date"
                      dataTestIdError="discount-valid-from-date"
                    ></Date>
                  </div>
                  <div>
                    <Date
                      v-model:dateTime="discount.activeTo"
                      id="active-to"
                      :label="t('discount.active-to')"
                      dataTestId="discount-active-to-date"
                      dataTestIdError="discount-valid-to-date"
                    ></Date>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>

      <Panel
        :header="t('discount.product.label')"
        toggleable
        :collapsed="panelCollapsedProduct"
        class="mb-4"
        :pt:header:onClick="() => (panelCollapsedProduct = !panelCollapsedProduct)"
      >
        <div class="col-span-12 lg:col-span-6 md:flex">
          <div class="col-span-12 md:col-span-3">
            <StatusCheckbox
              id="discount-active-for-all-products"
              :label="t(`discount.active-all-products`)"
              :disabled="true"
              v-model:value="discount.activeForAllProducts"
            />
          </div>
          <div class="col-span-12 md:col-span-4 w-1/5">
            <ProductSearch @productSelected="addProductToDiscountList" :discountRule="discount.discountRule" />
          </div>
        </div>
        <div class="col-span-12 mt-2">
          <ProductList
            :products="selectedProducts"
            :discountRule="discount.discountRule"
            :loading="loading"
            @removeProductClicked="removeProductFromDiscount"
            @fixedPriceUpdated="updateFixedPriceOnProduct"
            @discountPercentageUpdated="updateDiscountPercentageOnProduct"
          />
        </div>
      </Panel>

      <Panel
        :header="t('discount.manufacturer-group-level.label')"
        toggleable
        :collapsed="panelCollapsedManufacturerGroup"
        class="mb-4"
        :pt:header:onClick="() => (panelCollapsedManufacturerGroup = !panelCollapsedManufacturerGroup)"
      >
        <ManufacturerGroupLevelDiscount
          v-model:manufacturerGroupLevelDiscounts="discount.manufacturerGroupLevels"
          :loading="loading"
        />
      </Panel>

      <Panel
        :header="t('discount.customer.label')"
        toggleable
        :collapsed="panelCollapsedCustomer"
        class="mb-4"
        :pt:header:onClick="() => (panelCollapsedCustomer = !panelCollapsedCustomer)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-7 c-border-div">
            <div class="col-span-12 md:col-span-10 md:flex">
              <div class="col-span-12 md:col-span-4">
                <StatusCheckbox
                  id="discount-active-for-all-customers"
                  :label="t(`discount.active-all-customers`)"
                  v-model:value="discount.activeForAllCustomers"
                />
              </div>
              <div class="col-span-12 md:col-span-6 w-1/3">
                <CustomerSearch
                  :activeForAllCustomers="discount.activeForAllCustomers"
                  @customerSelected="addCustomerToDiscount"
                />
              </div>
            </div>
            <div class="col-span-12 mt-2">
              <CustomerList
                :customers="selectedCustomers"
                :loading="loading"
                @removeCustomerClicked="removeCustomerFromDiscount"
              />
            </div>
          </div>
          <div class="col-span-12 lg:col-span-5">
            <div class="col-span-12">
              <div class="col-span-12 md:col-span-4 lg:col-span-6">
                <CustomerGroupSearch @customerGroupSelected="addCustomerGroupToDiscount" />
              </div>
            </div>
            <div class="col-span-12 mt-1">
              <CustomerGroupList
                :customerGroups="selectedCustomerGroups"
                :loading="loading"
                @removeCustomerGroupClicked="removeCustomerGroupFromDiscount"
              />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { NIL as emptyUuid } from "uuid";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import useValidate from "@vuelidate/core";
import isEqual from "lodash.isequal";

import Name from "../components/Name.vue";
import Date from "../components/Date.vue";
import SelectDiscountType from "../components/SelectDiscountType.vue";
import SelectDiscountRule from "../components/SelectDiscountRule.vue";
import Description from "../components/Description.vue";
import SelectClient from "../components/SelectClient.vue";
import DiscountHeader from "../components/DiscountHeader.vue";
import { useDiscountService } from "../api/price/DiscountService";
import ProductSearch from "../components/ProductSearch.vue";
import ProductList from "../components/ProductList.vue";
import CustomerSearch from "../components/CustomerSearch.vue";
import CustomerList from "../components/CustomerList.vue";
import StatusCheckbox from "../components/StatusCheckbox.vue";
import CustomerGroupSearch from "../components/CustomerGroupSearch.vue";
import CustomerGroupList from "../components/CustomerGroupList.vue";
import ManufacturerGroupLevelDiscount from "../components/manufacturer-group-level/ManufacturerGroupLevelDiscount.vue";

import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { Discount } from "../model/discount/Discount";
import { DiscountRule } from "../model/discount/DiscountRule";
import { DiscountRoutes } from "../routes";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import { discountProductFunctions } from "../utils/discountProductFunctions";
import { discountCustomerFunctions } from "../utils/discountCustomerFunctions";
import { discountCustomerGroupFunctions } from "../utils/discountCustomerGroupFunctions";
import { useLanguageStore } from "../stores/LanguageStore";
import { useProductHierarchyStore } from "../stores/ProductHierarchyStore";

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const router = useRouter();
const { addDiscount } = useDiscountService();
const { getLanguageIso } = useLanguageStore();
const { getAllProductHierarchies } = useProductHierarchyStore();

const discount = ref<Discount>(new Discount());
const initialDiscount = ref<Discount>(new Discount());
const isSaving = ref<boolean>(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const loading = ref<boolean>(false);
const selectedProducts = ref<ProductWithDiscountInfo[]>([]);
const selectedCustomers = ref<SearchCustomer[]>([]);
const selectedCustomerGroups = ref<CustomerGroup[]>([]);
const confirmedDiscard = ref(false);

const {
  addProductToDiscountList,
  removeProductFromDiscount,
  updateDiscountPercentageOnProduct,
  updateFixedPriceOnProduct,
} = discountProductFunctions(discount, selectedProducts);

const { addCustomerToDiscount, removeCustomerFromDiscount } = discountCustomerFunctions(discount, selectedCustomers);

const { addCustomerGroupToDiscount, removeCustomerGroupFromDiscount } = discountCustomerGroupFunctions(
  discount,
  selectedCustomerGroups
);

const onDiscountRuleChanged = (discountRule: DiscountRule) => {
  discount.value.products.forEach((product) => {
    product.discountRule = discountRule;
  });
  selectedProducts.value.forEach((product) => {
    product.discountRule = discountRule;
  });
};

const addNewDiscount = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("validations.summary"),
      detail: t("validations.detail"),
      closable: true,
    });
    return;
  }

  if (discount.value.id === emptyUuid) {
    discount.value.id = uuidv4();
  }

  try {
    isSaving.value = true;

    await addDiscount(discount.value);

    toast.add({
      severity: "success",
      summary: t("discount.added.summary"),
      detail: t("discount.added.detail", { name: discount.value.name }),
      closable: true,
    });

    resetForm();
  } finally {
    isSaving.value = false;
  }
};

const resetForm = () => {
  val.value.$reset();
  discount.value = new Discount();
  selectedProducts.value = [];
  selectedCustomers.value = [];
  selectedCustomerGroups.value = [];
};

const routeToSearchPage = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null) {
    router.push({ name: DiscountRoutes.Search, query: { search: "" } });
  } else {
    router.back();
  }
};

const hasUpdates = computed(() => {
  return !isEqual(discount.value, initialDiscount.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToSearchPage();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    await getLanguageIso();
    await getAllProductHierarchies();
  } finally {
    loading.value = false;
  }

  document.addEventListener("keydown", handleKeydown);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    if (unsavedChangesDialogVisible.value) {
      routeToSearchPage();
    }
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const panelCollapsedProduct = ref(false);
const panelCollapsedManufacturerGroup = ref(false);
const panelCollapsedCustomer = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedProduct.value && panelCollapsedManufacturerGroup.value && panelCollapsedCustomer.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedProduct.value = newState;
  panelCollapsedManufacturerGroup.value = newState;
  panelCollapsedCustomer.value = newState;
};
</script>

<style scoped lang="scss">
.c-discount-margin {
  margin: var(--default-content-margin);
}

.c-spinner-container {
  position: relative;
  top: 175px;
}

.c-border-div {
  padding: 0.5rem;
  border-right: var(--footer-border);

  @media screen and (max-width: 992px) {
    border-right: none;
  }
}
:deep(.c-panel-content) {
  padding-top: 0;
}
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 65%;
}
.c-content-top-right {
  flex: 35%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}
</style>
