import { httpClient, AuthHeaders } from "@cumulus/http";
import { Client } from "./model/Client";

class ClientRepository {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? `${import.meta.env.VITE_APP_API_COMPANY}/clients`
        : `${import.meta.env.VITE_APP_API_URL as string}/clients`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Client> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }
}

const clientRepository = new ClientRepository();
export { clientRepository };
