{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "add": "Legg til",
    "cancel": "Avbryt",
    "save": "Lagre endringer",
    "delete": "Slett",
    "search": "Søk fraktmåter",
    "error": "En feil oppsto",
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Rett alle rød felter og prøv igjen"
    },
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Ikke funnet"
    },
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har noen ulagrede endringer. Du kan lagre endringene, forkaste endringene eller avbryte for å fortsette å redigere",
    "ignore": "Ignorer"
  },
  "freight": {
    "add-bring-service-agreement-header": "Legg til avtale",
    "update-bring-service-agreement-header": "Oppdater frakt avtale",
    "delete-bring-service-agreement-confirm-message": "Er du sikker på at du ønsker å slette avtalen?",
    "service-agreement-added": "Fraktavtale ble lagt til",
    "service-agreement-deleted": "Fraktavtale ble slettet",
    "service-agreement-updated": "Fraktavtale ble oppdatert",
    "header-add": "Legg til fraktmåte",
    "header-edit": "Endre fraktmåte",
    "name": "Navn",
    "customer-number": "Kundenummer",
    "client-url": "Klient URL",
    "carrier": "Transportør",
    "service-agreement": "Frakt via",
    "short-name": "Kortnavn",
    "methods": "Fraktmåter",
    "empty-list": "Ingen fraktmåter funnet",
    "loading-list": "Laster inn fraktmåter, vennligst vent...",
    "load-failed": "Kunne ikke laste inn fraktmetoder",
    "in-use": "Fraktmetoden er tilgjengelig, sett den til inaktiv for å slette den",
    "delete-confirm-message": "Er du sikker på at du vil slette denne fraktmåten?",
    "tax": "Mva",
    "default": "standard",
    "available": {
      "label": "Synlig for:",
      "customers-label": "Salg",
      "suppliers-label": "Innkjøp",
      "webshop-label": "Web"
    },
    "toast": {
      "add-success": {
        "summary": "Fraktmåte er lagt til!",
        "detail": "Fraktmåte: {name}"
      },
      "update-success": {
        "summary": "Fraktmåte er oppdatert!",
        "detail": "Fraktmåte: {name}"
      },
      "delete-success": {
        "summary": "Fraktmåte er slettet!",
        "detail": "Fraktmåte: {name}"
      }
    },
    "pick-service-agreement-placeholder": "Velg en fraktavtale",
    "pick-service-placeholder": "Velg en tjeneste",
    "my-bring-api-key": "MyBring Api Nøkkel",
    "my-bring-api-uid": "MyBring Api Uid"
  }
}
